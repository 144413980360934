.loading-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100vw;
    color: rgb(24, 23, 23);
    font-family: 'Arial', sans-serif;
    font-size: 24px;
    animation: pulse 2.5s infinite ease-in-out forwards;
    position: fixed;
    /* background-color: #484b4c; */
    /* background-color: #0e031e; */
    background: linear-gradient(119deg, rgb(0 19 93) 0%, #0e031e 100%);
    z-index: 1111111111111111111;
  }
  .overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0e031e;
    z-index: 111111;
  }


  .loading-page-container h1 {
    font-size: clamp(2rem 20vh 4rem);
    font-family: Neuemontreal, sans-serif !important;
    position: absolute;
    animation: interial 2.45s ease-in-out forwards;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20%;
    line-height: 1.03;
    font-style: normal;
    font-weight: 450;
    overflow: hidden;
  }
  
  @keyframes interial {
    0% {
      bottom: 20%;
    }
    40% {
      bottom: 50%;
      transform: translate(-50%, 0%);
    }
    70% {
      bottom: 50%;
      transform: translate(-50%, 0%);
    }
    100% {
      bottom: 20%;
      transform: translate(-50%, 0%);
    }
  }

  .slide_up {
    animation: slideUp 2.5s cubic-bezier(1, 0.5, 0.2, 1) forwards;
  }

  .move-off-top {
    animation: moveOffTop 2s cubic-bezier(1, 0.5, 0.2, 1) forwards;
  }
  @keyframes slideUp {
    0% {
      bottom: -100%;
      transform: scaleY(0);
    }
    25% {
      bottom: 0;
      transform: scaleY(1);
    }
    75% {
      bottom: 0;
      transform: scaleY(1);
    }
    100% {
      bottom: 100%;
      transform: scaleY(1);
    }
  }
  @keyframes moveOffTop {
    0% {
      bottom: 0%;
      transform: scaleY(1);
    }
    100% {
      bottom: 100%;
      transform: scaleY(0);
    }
  }







  
  @keyframes pulse {
    0% {
      text-shadow: 0 0 10px white;
    }
    50% {
      text-shadow: 0 0 20px rgb(44, 145, 145);
    }
    75% {
      text-shadow: 0 0 10px rgb(6, 133, 133);
    }
    100% {
      text-shadow: 0 0 10px rgb(216, 225, 229);
    }
  }
  /* class="a-fadein" */
  .blob {
    animation: fadeIn 1s;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    position: absolute;
    -webkit-filter: blur(250px);
    aspect-ratio: 1 / 1;
    background-color: rgb(124, 202, 205);
    filter: blur(250px);
    opacity: 0.5;
  }
  .blob.blob_2{
    width: 591px;
    height: 591px;
    top: -50px;
    left: -50px;
    filter: blur(250px);
    overflow: hidden;
    border-radius: 500px;
    background: #CD5715;
    background: radial-gradient(circle, rgba(205, 87, 21, 1) 0%, rgba(88, 39, 148, 1) 100%);
    transform: translate(-50%, -50%);
    opacity: 0.5;
  }
 
  .blob.blob_3{
    bottom: -50px;
    right: -50px;
    background-color: #7c6cff;
    opacity: 0.5;
  }


  .loading-page-container h3{
    font-size: 2rem;
    color: #fff;
    text-align: center;
    font-weight: bold;
    letter-spacing: 0.4rem;
    text-transform: uppercase;
  }

  

  .my-custom-class{
    position: absolute;
  }

  @keyframes rotate {
    0% {
      transform: spin(0);
    }
    100% {
      transform: spin(360deg);
    }
  }
