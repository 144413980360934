
.stars {
    position: relative;
    width: 100%;
    z-index: 100;
    height: 120vh;
    transform: rotate(-45deg);
  }

  .star {
    $star-count: 50;
    --star-color: white;
    --star-tail-length: 6em;
    --star-tail-height: 2px;
    --star-width: calc(var(--star-tail-length) / 6);
    --fall-duration: 9s;
    --tail-fade-duration: var(--fall-duration);

    position: absolute;
    z-index: -100;
    top: var(--top-offset);
    left: 0;
    width: var(--star-tail-length);
    height: var(--star-tail-height);
    color: var(--star-color);
    background: linear-gradient(45deg, currentColor, transparent);
    border-radius: 50%;
    filter: drop-shadow(0 0 6px currentColor);
    transform: translate3d(104em, 0, 0);
    animation: fall var(--fall-duration) var(--fall-delay) linear infinite, tail-fade var(--tail-fade-duration) var(--fall-delay) ease-out infinite;
  }

  /* if require not tailed stars use this instead */
  /* .star {
    position: absolute;
    left: 0;
    width: var(--star-tail-length, 6em);
    height: var(--star-tail-height, 2px);
    background: linear-gradient(45deg, white, transparent);
    border-radius: 50%;
    filter: drop-shadow(0 0 6px white);
    transform: translate3d(104em, 0, 0);
    animation: fall var(--fall-duration, 9s) var(--fall-delay, 0s) linear infinite, 
               tail-fade var(--fall-duration, 9s) var(--fall-delay, 0s) ease-out infinite;
} */


.app .stars {
    height: 120%;
    left: 0;
    position: fixed;
    top: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: 100%;
    z-index: -100
}

.app .star {
    --star-color: #fff;
    --star-tail-length: 6em;
    --star-tail-height: 2px;
    --star-width: calc(var(--star-tail-length)/6);
    --fall-duration: 9s;
    --tail-fade-duration: var(--fall-duration);
    -webkit-animation: fall var(--fall-duration) var(--fall-delay) linear infinite,tail-fade var(--tail-fade-duration) var(--fall-delay) ease-out infinite;
    animation: fall var(--fall-duration) var(--fall-delay) linear infinite,tail-fade var(--tail-fade-duration) var(--fall-delay) ease-out infinite;
    background: linear-gradient(45deg,currentColor,transparent);
    border-radius: 50%;
    color: var(--star-color);
    -webkit-filter: drop-shadow(0 0 6px currentColor);
    filter: drop-shadow(0 0 6px currentColor);
    height: var(--star-tail-height);
    left: 0;
    position: absolute;
    top: var(--top-offset);
    -webkit-transform: translate3d(104em,0,0);
    transform: translate3d(104em,0,0);
    width: var(--star-tail-length);
    z-index: -100
}

@media screen and (max-width: 750px) {
    .app .star {
        -webkit-animation:fall var(--fall-duration) var(--fall-delay) linear infinite;
        animation: fall var(--fall-duration) var(--fall-delay) linear infinite
    }
}

.app .star:first-child {
    --star-tail-length: 5.38em;
    --top-offset: 4.68vh;
    --fall-duration: 8.376s;
    --fall-delay: 8.755s
}

.app .star:nth-child(2) {
    --star-tail-length: 6.73em;
    --top-offset: 5.46vh;
    --fall-duration: 6.755s;
    --fall-delay: 7.891s
}

.app .star:nth-child(3) {
    --star-tail-length: 6.47em;
    --top-offset: 43.14vh;
    --fall-duration: 11.08s;
    --fall-delay: 3.67s
}

.app .star:nth-child(4) {
    --star-tail-length: 6.79em;
    --top-offset: 17.2vh;
    --fall-duration: 11.908s;
    --fall-delay: 0.943s
}

.app .star:nth-child(5) {
    --star-tail-length: 6.15em;
    --top-offset: 16.04vh;
    --fall-duration: 8.954s;
    --fall-delay: 2.633s
}

.app .star:nth-child(6) {
    --star-tail-length: 5.1em;
    --top-offset: 66.1vh;
    --fall-duration: 9.052s;
    --fall-delay: 1.982s
}

.app .star:nth-child(7) {
    --star-tail-length: 5.07em;
    --top-offset: 76.33vh;
    --fall-duration: 11.036s;
    --fall-delay: 1.486s
}

.app .star:nth-child(8) {
    --star-tail-length: 7.37em;
    --top-offset: 69.98vh;
    --fall-duration: 10.948s;
    --fall-delay: 3.076s
}

.app .star:nth-child(9) {
    --star-tail-length: 7.06em;
    --top-offset: 21.97vh;
    --fall-duration: 7.57s;
    --fall-delay: 8.014s
}

.app .star:nth-child(10) {
    --star-tail-length: 5.72em;
    --top-offset: 52.31vh;
    --fall-duration: 11.412s;
    --fall-delay: 8.98s
}

.app .star:nth-child(11) {
    --star-tail-length: 6.66em;
    --top-offset: 63.55vh;
    --fall-duration: 11.653s;
    --fall-delay: 5.693s
}

.app .star:nth-child(12) {
    --star-tail-length: 7.13em;
    --top-offset: 65vh;
    --fall-duration: 11.341s;
    --fall-delay: 1.936s
}

.app .star:nth-child(13) {
    --star-tail-length: 6.49em;
    --top-offset: 92.82vh;
    --fall-duration: 10.236s;
    --fall-delay: 9.387s
}

.app .star:nth-child(14) {
    --star-tail-length: 5em;
    --top-offset: 50.4vh;
    --fall-duration: 10.599s;
    --fall-delay: 5.115s
}

.app .star:nth-child(15) {
    --star-tail-length: 6.94em;
    --top-offset: 19.07vh;
    --fall-duration: 10.435s;
    --fall-delay: 7.389s
}

.app .star:nth-child(16) {
    --star-tail-length: 5.85em;
    --top-offset: 97.41vh;
    --fall-duration: 9.53s;
    --fall-delay: 5.74s
}

.app .star:nth-child(17) {
    --star-tail-length: 6.09em;
    --top-offset: 30.34vh;
    --fall-duration: 6.544s;
    --fall-delay: 9.61s
}

.app .star:nth-child(18) {
    --star-tail-length: 5.89em;
    --top-offset: 24.51vh;
    --fall-duration: 7.948s;
    --fall-delay: 8.584s
}

.app .star:nth-child(19) {
    --star-tail-length: 5.23em;
    --top-offset: 55.27vh;
    --fall-duration: 11.946s;
    --fall-delay: 4.928s
}

.app .star:nth-child(20) {
    --star-tail-length: 5.27em;
    --top-offset: 87.5vh;
    --fall-duration: 8.269s;
    --fall-delay: 8.26s
}

.app .star:nth-child(21) {
    --star-tail-length: 5.7em;
    --top-offset: 46.88vh;
    --fall-duration: 11.31s;
    --fall-delay: 7.254s
}

.app .star:nth-child(22) {
    --star-tail-length: 5.86em;
    --top-offset: 75.37vh;
    --fall-duration: 11.703s;
    --fall-delay: 4.987s
}

.app .star:nth-child(23) {
    --star-tail-length: 5.14em;
    --top-offset: 90.13vh;
    --fall-duration: 7.29s;
    --fall-delay: 7.023s
}

.app .star:nth-child(24) {
    --star-tail-length: 7.31em;
    --top-offset: 22.55vh;
    --fall-duration: 10.8s;
    --fall-delay: 5.847s
}

.app .star:nth-child(25) {
    --star-tail-length: 6.81em;
    --top-offset: 3.55vh;
    --fall-duration: 10.769s;
    --fall-delay: 7.297s
}

.app .star:nth-child(26) {
    --star-tail-length: 7.2em;
    --top-offset: 66.93vh;
    --fall-duration: 8.76s;
    --fall-delay: 6.896s
}

.app .star:nth-child(27) {
    --star-tail-length: 5.68em;
    --top-offset: 79.27vh;
    --fall-duration: 10.041s;
    --fall-delay: 9.932s
}

.app .star:nth-child(28) {
    --star-tail-length: 5.39em;
    --top-offset: 19.87vh;
    --fall-duration: 10.095s;
    --fall-delay: 8.182s
}

.app .star:nth-child(29) {
    --star-tail-length: 5.16em;
    --top-offset: 23.75vh;
    --fall-duration: 9.279s;
    --fall-delay: 4.643s
}

.app .star:nth-child(30) {
    --star-tail-length: 5.36em;
    --top-offset: 4.53vh;
    --fall-duration: 7.585s;
    --fall-delay: 7.453s
}

.app .star:nth-child(31) {
    --star-tail-length: 5.31em;
    --top-offset: 8.79vh;
    --fall-duration: 8.068s;
    --fall-delay: 0.304s
}

.app .star:nth-child(32) {
    --star-tail-length: 6.47em;
    --top-offset: 1.78vh;
    --fall-duration: 9.704s;
    --fall-delay: 6.761s
}

.app .star:nth-child(33) {
    --star-tail-length: 7.2em;
    --top-offset: 18.74vh;
    --fall-duration: 8.58s;
    --fall-delay: 8.933s
}

.app .star:nth-child(34) {
    --star-tail-length: 7.44em;
    --top-offset: 70.36vh;
    --fall-duration: 9.882s;
    --fall-delay: 7.247s
}

.app .star:nth-child(35) {
    --star-tail-length: 5.35em;
    --top-offset: 49.84vh;
    --fall-duration: 7.103s;
    --fall-delay: 0.78s
}

.app .star:nth-child(36) {
    --star-tail-length: 5.32em;
    --top-offset: 49.71vh;
    --fall-duration: 6.484s;
    --fall-delay: 9.107s
}

.app .star:nth-child(37) {
    --star-tail-length: 5.84em;
    --top-offset: 99.16vh;
    --fall-duration: 9.168s;
    --fall-delay: 8.605s
}

.app .star:nth-child(38) {
    --star-tail-length: 6.98em;
    --top-offset: 78.43vh;
    --fall-duration: 6.003s;
    --fall-delay: 2.404s
}

.app .star:nth-child(39) {
    --star-tail-length: 5.74em;
    --top-offset: 74.4vh;
    --fall-duration: 9.869s;
    --fall-delay: 0.588s
}

.app .star:nth-child(40) {
    --star-tail-length: 6.88em;
    --top-offset: 16.71vh;
    --fall-duration: 6.802s;
    --fall-delay: 4.726s
}

.app .star:nth-child(41) {
    --star-tail-length: 7.47em;
    --top-offset: 50.34vh;
    --fall-duration: 6.168s;
    --fall-delay: 9.114s
}

.app .star:nth-child(42) {
    --star-tail-length: 6.27em;
    --top-offset: 70.2vh;
    --fall-duration: 10.236s;
    --fall-delay: 7.809s
}

.app .star:nth-child(43) {
    --star-tail-length: 5.2em;
    --top-offset: 90.17vh;
    --fall-duration: 11.896s;
    --fall-delay: 2.568s
}

.app .star:nth-child(44) {
    --star-tail-length: 5.75em;
    --top-offset: 23.39vh;
    --fall-duration: 11.322s;
    --fall-delay: 5.242s
}

.app .star:nth-child(45) {
    --star-tail-length: 7.4em;
    --top-offset: 4.16vh;
    --fall-duration: 11.122s;
    --fall-delay: 2.732s
}

.app .star:nth-child(46) {
    --star-tail-length: 6.4em;
    --top-offset: 24.14vh;
    --fall-duration: 7.866s;
    --fall-delay: 6.559s
}

.app .star:nth-child(47) {
    --star-tail-length: 7.46em;
    --top-offset: 28.13vh;
    --fall-duration: 11.175s;
    --fall-delay: 7.805s
}

.app .star:nth-child(48) {
    --star-tail-length: 6.99em;
    --top-offset: 52.92vh;
    --fall-duration: 10.509s;
    --fall-delay: 0.329s
}

.app .star:nth-child(49) {
    --star-tail-length: 5.36em;
    --top-offset: 46.7vh;
    --fall-duration: 7.484s;
    --fall-delay: 6.256s
}

.app .star:nth-child(50) {
    --star-tail-length: 5.6em;
    --top-offset: 34.19vh;
    --fall-duration: 7.932s;
    --fall-delay: 3.278s
}

.app .star:after,.app .star:before {
    -webkit-animation: blink 2s linear infinite;
    animation: blink 2s linear infinite;
    background: linear-gradient(45deg,transparent,currentColor,transparent);
    border-radius: inherit;
    content: "";
    height: 100%;
    left: calc(var(--star-width)/-2);
    position: absolute;
    top: 0;
    width: var(--star-width);
    z-index: -100
}

.app .star:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.app .star:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

@-webkit-keyframes fall {
    to {
        -webkit-transform: translate3d(-30em,0,0);
        transform: translate3d(-30em,0,0)
    }
}

@keyframes fall {
    to {
        -webkit-transform: translate3d(-30em,0,0);
        transform: translate3d(-30em,0,0)
    }
}

@-webkit-keyframes tail-fade {
    0%,50% {
        opacity: 1;
        width: var(--star-tail-length);
        z-index: -100
    }

    70%,80% {
        opacity: .4;
        width: 0;
        z-index: -100
    }

    to {
        opacity: 0;
        width: 0;
        z-index: -100
    }
}

@keyframes tail-fade {
    0%,50% {
        opacity: 1;
        width: var(--star-tail-length);
        z-index: -100
    }

    70%,80% {
        opacity: .4;
        width: 0;
        z-index: -100
    }

    to {
        opacity: 0;
        width: 0;
        z-index: -100
    }
}

@-webkit-keyframes blink {
    50% {
        opacity: .6;
        z-index: -100
    }
}

@keyframes blink {
    50% {
        opacity: .6;
        z-index: -100
    }
}