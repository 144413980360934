/* ------------------------- Style ® Code by Dennis -------------------------------------------------- */

:root {
   --primary-color: #3498db;
   --color-white: rgb(255, 255, 255);
   --color-dark-dark: #141517;
   --font-family: 'Arial, sans-serif';
   --padding: 10px;
 }

main {
   box-sizing: border-box;
   overflow: hidden;
   width: 100%;
   position: fixed;
   height: 100%;
   background: #0e031e;
}

.main-wrap::after {
   display: block;
   position: absolute;
   content: "";
   width: 100%;
   height: 5vh;
   top: 99%;
   left: 0;
   background: var(--color-dark);
   z-index: -1;
}

.main-wrap {
   box-sizing: border-box;
   width: 100vw;
    /*will-change: transform;*/
   position: fixed;
}

main.touch {
   position: relative;
   height: auto;
}

main.touch .main-wrap {
   position: relative;
}

@media screen and (max-width: 1024px) {
   main {
      position: relative;
   }
   .main-wrap {
      position: relative;
   }
}

/* ------------------------- Page Transition -------------------------------------------------- */

.no-scroll-overlay {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100vh;
   overflow: hidden;
   z-index: 900;
   display: none;
}

.loading-container {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100vh;
   overflow: hidden;
   z-index: 800;
   pointer-events: none;
   background: transparent;
}

.loading-screen {
   position: relative;
   padding: 0;
   width: 100%;
   height: 100%;
   left: 0;
   will-change: top;
}

.loading-screen,
.loading-container .rounded-div-wrap.top .rounded-div,
.loading-container .rounded-div-wrap.bottom .rounded-div {
   background: var(--color-dark-dark);
}
.loading-container .rounded-div-wrap.top .rounded-div,
.loading-container .rounded-div-wrap.bottom .rounded-div {
   height: 745%;
}

.loading-container .rounded-div-wrap.top {
   top: 0;
   position: absolute;
   transform: translateY(-99%) scale(1,-1);
   z-index: 2;
   height: 0vh;
   will-change: height;
}

.loading-container .rounded-div-wrap.bottom {
   top: unset;
   bottom: 0;
   position: absolute;
   transform: translateY(99%);
   z-index: 2;
   height: 0vh;
   will-change: height;
}

.loading-container .loading-words {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   opacity: 0;
   pointer-events: none;
   display: block;
   will-change: opacity;
}

.loading-container .loading-words h2 {
   margin: 0;
   padding: 0;
   color: var(--color-white);
   position: absolute;
   top:0;
   left: 50%;
   transform: translateX(-50%);
   display: none;
   padding-left: .6em;
   white-space: nowrap;
   padding-right: .4em;
   will-change: opacity;
}

.loading-container .loading-words h2.jap {
   font-size: calc(clamp(3.25em, 5vw, 4.5em) * .65);
   transform: translate(-50%, 10%);
}

.loading-container .dot {
   display: block;
   position: absolute;
   border-radius: 50%;
   background: var(--color-white);
   left: 0;
   top: 47%;
   bottom: auto;
   width: calc(clamp(16px, 1.2vw, 19px) / 1.65);
   height: calc(clamp(16px, 1.2vw, 19px) / 1.65);
   transform: translate(0, 0) scale(1);
}

.loading-container .loading-words h2.active {
   display: block;
}

.loading-container .loading-words .home-active,
.loading-container .loading-words .home-active-last {
   opacity: 0;
   display: none;
}

.loading-fade {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100vh;
   overflow: hidden;
   z-index: 90;
   background: #FFF;
   visibility: hidden;
   opacity: 0;
}

.once-in {
    /*will-change: transform;*/
}

@media screen and (max-width: 540px) {

   /* .loading-container .loading-words h2 {
      padding-left: 0;
   } */

   /* .loading-container .dot {
      display: none;
   } */

   .loading-container .dot {
      top: 42%;
   }
}


/* ------------------------- Top Bar -------------------------------------------------- */

.nav-bar {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   background: transparent;
   z-index: 5;
   transform: translate(0,0) rotate(0.001deg);
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: calc(var(--gap-padding) / 2) calc(var(--gap-padding) / 2);
}

.nav-bar .links-wrap li:last-child {
   display: none;
}

@media screen and (max-width: 540px){ 

   .nav-bar {
      padding: calc(var(--gap-padding) / 1) calc(var(--gap-padding) * .5) calc(var(--gap-padding) / 1) calc(var(--gap-padding) * .33);
   }

   .nav-bar .links-wrap li {
      display: none;
   }

   .nav-bar .links-wrap li:last-child {
      display: block;
   }
}

/* ------------------------- Fixed Nav -------------------------------------------------- */

.fixed-nav-back {
   background: linear-gradient(to right,hsla(220, 13%, 0%, .3) 40%, hsla(220, 13%, 0%, 1) 80%);
   opacity: 0;
   transition: opacity .8s cubic-bezier(.7, 0, .2, 1);
   z-index: 100;
   pointer-events: none;
   will-change: opacity;
}

.nav-active .fixed-nav-back {
   opacity: .35;
   pointer-events: all;
}

.fixed-nav {
   position: fixed;
   right: 0;
   top: 0;
   height: 100vh;
   /* background: var(--color-dark); */
   /* background: linear-gradient(119deg, rgb(0 19 93) 0%,  #0b3cff 100% ); */
   background: linear-gradient(119deg, rgb(0 19 93) 0%,  #0e031e 100% );
   z-index: 100;
   transform: translate(calc(100% + 6vw),0) rotate(0.001deg);
   transition: transform .8s cubic-bezier(.7, 0, .2, 1);
    /*will-change: transform;*/
}

.nav-active .fixed-nav {
   transform: translate(0,0) rotate(0.001deg);
}

.fixed-nav-inner {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   height: 100%;
   padding: 15vh 7.5vw 10vh 7.5vw;
   position: relative;
   transform: translate(0,0) rotate(0.001deg);
   transition: all .6s cubic-bezier(.7, 0, .2, 1);
    /*will-change: transform;*/
}

.nav-active .fixed-nav-inner {
   transform: translate(0,0) rotate(0.001deg);
}

.fixed-nav .nav-row h5 {
   margin-bottom: 3em;
}

.fixed-nav .nav-row ul {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   padding-top: 5vh;
}

.fixed-nav .nav-row .btn-link .btn-click {
   height: auto;
}

.fixed-nav .nav-row ul {
   margin-left: calc(var(--gap-padding) / -2);
}

.fixed-nav .nav-row .btn-text-inner {
   font-size: 3.5em;
   line-height: 1.4;
   font-size: calc(clamp(3.25em, 5vw, 4em) * .875);
}

.fixed-nav .nav-row .btn-link .btn-click::after {
   background: var(--color-white);
   left: calc(var(--gap-padding) / -4);
   top: 50%;
   bottom: auto;
   width: calc(clamp(16px, 1.2vw, 19px) / 1.65);
   height: calc(clamp(16px, 1.2vw, 19px) / 1.65);
}

.fixed-nav .social-row {
   margin-left: calc(var(--gap-padding) / -4);
}

.fixed-nav-rounded-div {
   position: absolute;
   left: 1px;
   transform: translateX(-100%);
   height: 100%;
   top: 0;
}

.fixed-nav-rounded-div .rounded-div-wrap {
   width: 6vw;
   height: 100%;
   transition: var(--animation-slow);
   transition: all .85s cubic-bezier(.7, 0, .2, 1);
   will-change: width;
}

.nav-active .fixed-nav-rounded-div .rounded-div-wrap {
   width: 0vw;
}

.fixed-nav-rounded-div .rounded-div-wrap .rounded-div {
   /* background: ar(--color-dvark); */
   background: linear-gradient(119deg, rgb(0, 17, 85)10%,  #0e031e 100% );
   height: 150%;
   content: "";
   display: block;
   position: absolute;
   width: 775%;
   top: 50%;
   border-radius: 50%;
   transform: translate(-6.5%, -50%);
}

.fixed-nav .nav-row ul .btn {
   transform: translate(15vw,0) rotate(0.001deg);
   transition: all .8s cubic-bezier(.7, 0, .2, 1);
    /*will-change: transform;*/
}

.nav-active .fixed-nav .nav-row ul .btn {
   transform: translate(0,0) rotate(0.001deg);
}

.fixed-nav .nav-row ul .btn:nth-child(2) {transition-delay: .03s;}
.fixed-nav .nav-row ul .btn:nth-child(3) {transition-delay: .06s;}
.fixed-nav .nav-row ul .btn:nth-child(4) {transition-delay: .09s;}

.fixed-nav .social-row .stripe {
   display: none;
}

@media screen and (max-height: 680px){
   .fixed-nav .nav-row .btn-text-inner {
      font-size: 3em;
      line-height: 1.25;
   }
}

@media screen and (max-width: 540px){ 

   .fixed-nav-back {
      background: hsla(220, 13%, 5%, 1);
   }

   .fixed-nav {
      width: 100%;
      transform: translate(calc(100% + 20vw),0) rotate(0.001deg);
   }

   .fixed-nav .fixed-nav-inner {
      height: calc(var(--vh, 1vh) * 100);
      padding: calc(var(--vh, 1vh) * 15) var(--container-padding) calc(var(--gap-padding) * 1.25) var(--container-padding);
      transform: translate(20vw,0) rotate(0.001deg);
      transition-delay: 0s;
   }

   .nav-active .fixed-nav .fixed-nav-inner {
      transform: translate(0,0) rotate(0.001deg);
      transition-delay: .1s;
   }

   .fixed-nav .nav-row .btn-link .btn-click::after {
      background: var(--color-white);
      left: auto;
      right: .4em;
      top: 50%;
      bottom: auto;
      width: calc(clamp(16px, 1.2vw, 19px) / 1.5);
      height: calc(clamp(16px, 1.2vw, 19px) / 1.5);
   }

   .fixed-nav .nav-row ul {
      width: 100%;
      padding-top: calc(var(--vh, 1vh) * 3);
      padding-bottom: calc(var(--vh, 1vh) * 3);
   }

   .fixed-nav .nav-row ul .btn {
      width: 100%;
      transition: all .8s cubic-bezier(.7, 0, .2, 1);
   }

   .fixed-nav .nav-row ul .btn .btn-text {
      justify-content: flex-start;
   }

   .fixed-nav .social-row .stripe {
      display: block;
      margin-bottom: calc(var(--section-padding) / 3);
   }

   .fixed-nav-rounded-div .rounded-div-wrap {
      width: 20vw;
   }

   .nav-active .fixed-nav-rounded-div .rounded-div-wrap {
      width: 0;
   }

   .fixed-nav .nav-row ul .btn:nth-child(1) {transition-delay: 0s;}
   .fixed-nav .nav-row ul .btn:nth-child(2) {transition-delay: .03s;}
   .fixed-nav .nav-row ul .btn:nth-child(3) {transition-delay: .06s;}
   .fixed-nav .nav-row ul .btn:nth-child(4) {transition-delay: .09s;}

   .nav-active .fixed-nav .nav-row ul .btn:nth-child(1) {transition-delay: .1s;}
   .nav-active .fixed-nav .nav-row ul .btn:nth-child(2) {transition-delay: .13s;}
   .nav-active .fixed-nav .nav-row ul .btn:nth-child(3) {transition-delay: .16s;}
   .nav-active .fixed-nav .nav-row ul .btn:nth-child(4) {transition-delay: .19s;}
}


/* ------------------------- Footer -------------------------------------------------- */

.footer-wrap {
   display: block;
   width: 100%;
   position: relative;
   overflow: hidden;
   /* background: var(--color-dark-dark); */
   display: flex;
   align-items: flex-end;
   box-shadow: 0px 5px 0px 5px rgb(0, 31, 153);
   
   background: linear-gradient(119deg, rgba(14, 3, 30, 0.918) -40%, rgb(0, 31, 153) 100%) !important;
}

.footer-wrap.theme-dark .footer{
   background: var(--color-dark);
}

.footer-wrap.theme-dark .overlay-gradient {
   pointer-events: none;
   height: calc(var(--section-padding) * .75);
   background: linear-gradient(to bottom,hsla(225, 7%, 12%) 0%, hsla(225, 7%, 12%, 0.987) 8.1%, hsla(225, 7%, 12%, 0.951) 15.5%, hsla(225, 7%, 12%, 0.896) 22.5%, hsla(225, 7%, 12%, 0.825) 29%, hsla(225, 7%, 12%, 0.741) 35.3%,  hsla(225, 7%, 12%, 0.648) 41.2%,  hsla(225, 7%, 12%, 0.55) 47.1%, hsla(225, 7%, 12%, 0.45) 52.9%, hsla(225, 7%, 12%, 0.352) 58.8%, hsla(225, 7%, 12%, 0.259) 64.7%, hsla(225, 7%, 12%, 0.175) 71%, hsla(225, 7%, 12%, 0.104) 77.5%, hsla(225, 7%, 12%, 0.049) 84.5%, hsla(225, 7%, 12%, 0.013) 91.9%, hsla(225, 7%, 12%, 0) 100%);
   opacity: .75;
}

.footer-rounded-div {
   width: 100%;
   position: relative;
   height: 0;
   display: block;
   z-index: 2;
}

.footer-rounded-div .rounded-div-wrap {
   transform: translateY(-1px);
   will-change: height;
}

.footer {
   padding-bottom: 0;
   width: 100%;
    /*will-change: transform;*/
}

.footer .dots {
   width: calc(var(--container-padding) * 3);
}
/* 
.footer h2 {
   font-size: calc(clamp(3.25em, 7vw, 8em) * .875) !important;
}
.footer h2 span {
   font-size: calc(clamp(3.25em, 7vw, 8em) * .875) !important;
} */
.footer-text-size {
   font-size: calc(clamp(2em, 7vw, 2.7rem) * .875) !important;
}
.footer h2 span {
   display: block;
}

.footer .container.medium {
   display: flex;
   flex-direction: column;
}

.footer .container.medium .row:nth-child(1) {
   padding-bottom: calc(var(--section-padding) / 2);
}

.footer .container.medium .row:nth-child(2) .btn-fixed {
   position: absolute;
   right: 0;
   top: 0;
   transform: translate(-50%, -50%);
   z-index: 20;
}

.footer .container.medium .row:nth-child(2) .btn-fixed .btn {
    /*will-change: transform;*/
}

.footer .container.medium .row:nth-child(2) {
   padding-bottom: calc(var(--section-padding) * .475);
}

.footer .container.medium .row:nth-child(3) .flex-col {
   display: flex;
   flex-direction: row;
}

.footer .container.medium .row:nth-child(3) .flex-col .btn {
   display: inline-flex;
   margin-right: 0.5em;
   margin-bottom: 0;
}

.footer .arrow {
   position: absolute;
   right: 0;
   bottom: calc(var(--gap-padding) * 1.5);
    /*will-change: transform;*/
}

.footer .profile-picture {
   margin-right: .125em;
   height: .85em;
   width: .85em;
   transform: translateY(.09em);
}

.footer .arrow svg {
   transform: rotate(90deg);
}

.bottom-footer {
   padding: calc(var(--section-padding) / 1.3) calc(var(--gap-padding) / 1.33) calc(var(--gap-padding) / 1.75) calc(var(--gap-padding) / 1);
   flex-direction: row;
   justify-content: space-between;
}

.bottom-footer .stripe {
   display: none;
}

.bottom-footer .flex-col {
   display: flex;
   width: auto;
   justify-content: space-between;
}

.bottom-footer .credits {
   padding-right: var(--gap-padding);
}

.socials li {
   display: inline-flex;
   font-size: .85em;
}

.bottom-footer p {
   font-size: .85em;
}

.bottom-footer h5 {
   margin-bottom: 1.5em;
}

.socials h5 {
   padding-left: calc(var(--gap-padding) * .5);
   margin-bottom: 1em;
}

.bottom-footer #timeSpan {
   text-transform: uppercase;
}

@media screen and (max-width: 900px){

   .footer .container.medium .row:nth-child(2) {
      padding-bottom: calc(var(--section-padding) * .75);
   }
}

@media screen and (max-width: 720px){

   .footer-rounded-div .rounded-div-wrap{
      height: 7.5vh;
   }

   .footer .container.medium .row:nth-child(3) .flex-col {
      flex-direction: column;
   }

   .footer .container.medium .row:nth-child(3) .flex-col .btn {
      width: 100%;
      margin: 0;
      margin-bottom: .75em;
   }

   .footer .container.medium .row:nth-child(3) .flex-col .btn .btn-click {
      width: 100%;
   }

   .footer .container.medium .row:nth-child(1) {
      padding-bottom: calc(var(--section-padding) * .75);
   }

   .footer .container.medium .row:nth-child(2) {
      padding-bottom: calc(var(--section-padding) * 1.25);
   }

   .footer .container.medium .row:nth-child(3) {
      padding-bottom: calc(var(--section-padding) / 5);
   }

   .footer .container.medium .row:nth-child(2) .btn-fixed {
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(-20%, -50%);
   }

   .bottom-footer {
      padding: 5vh 0 calc(var(--gap-padding) / 1.75) 0;
   }

   .bottom-footer .flex-col {
      width: 100%;
   }

   .bottom-footer .flex-col:nth-child(2) {
      padding: 0 calc(var(--container-padding) - calc(var(--gap-padding) * .333)) calc(var(--section-padding) / 3) calc(var(--container-padding) - calc(var(--gap-padding) * .333));
      width: 100%;
   }

   .bottom-footer .flex-col:nth-child(1) {
      order: 3;
      padding: 0 var(--container-padding) calc(var(--gap-padding) * .75) var(--container-padding);
   }

   .socials li {
      display: inline-flex;
      font-size: 1em;
      margin-right: 0;
   }
   
   .bottom-footer p {
      font-size: 1em;
   }

   .footer .container.medium .row:nth-child(2) .stripe {
      display: block;
   }

   .bottom-footer .stripe {
      display: block;
      margin-top: var(--gap-padding);
      width: calc(100% - (var(--gap-padding) * .666));
      left: 50%;
      margin-left: calc(var(--gap-padding) * .333);
   }

   .socials,
   .socials ul {
      width: 100%;
   }

   .footer .arrow {
      position: absolute;
      right: 0;
      bottom: 1em;
   }
   
   .footer .profile-picture {
      margin-right: .125em; 
      height: .95em;
      width: .95em;
      transform: translateY(.1em);
   }
   
}

/* ------------------------- Footer - Contact -------------------------------------------------- */

.footer-contact {
   background: var(--color-dark);
   margin-top: -1px;
   padding-top: 0;
   box-shadow: 0px 5px 0px 5px var(--color-dark);
}

.footer-contact .bottom-footer {
   padding-top: 0;
}

/* ------------------------- Home - Header -------------------------------------------------- */

.home-header {
   min-height: 115vh;
   display: flex;
   align-items: center;
   background: var(--color-gray);
   overflow: hidden;
}

.home-header .personal-image-wrap {
   top: 50%;
   left: 50%;
   height: 100%;
   width: 100%;
   display: block;
   position: absolute;
   transform: translate(-50%, -50%);
}

.home-header .personal-image {
   top: -10%;
   width: 100%;
   height: 110%;
   background: var(--color-gray);
}

.home-header .personal-image img {
   width: auto;
   height: 100%;
   object-fit: cover;
   position: absolute;
   left: 50%;
   transform: translateX(-50%);
}

.home-header .big-name {
   position: absolute;
   bottom: 15vh;
   left: 0;
   width: 100%;
   padding-bottom: calc(var(--gap-padding) * 1.25);
}

.home-header .big-name .name-h1 {
   position: relative;
   display: flex;
   width: 100%;
}

.home-header .big-name .name-wrap {
   position: relative;
   display: flex;
   align-items: center;
}

.home-header .big-name .name-wrap .spacer {
   padding: 0 3vw;
}

.home-header .big-name h1 {
   position: relative;
   display: flex;
   align-items: center;
   color: var(--color-white);
   line-height: 1;
   font-size: 17.5vw;
   font-size: max(9em, 15vw);
   white-space: nowrap;
   pointer-events: none;
   margin: 0;
   padding: 0;
}

.home-header .container {
   width: 100%;
   padding-bottom: 15vh;
}

.home-header .row {
   justify-content: flex-end;
}

.home-header .row .flex-col {
   width: auto;
}

.home-header .row .flex-col .header-above-h4 {
   top: -12.5vh;
   position: absolute;
}

.home-header .row .flex-col .header-above-h4 svg {
   width: clamp(1.1em, 1.3vw, 1.25em);
}

.home-header .row .flex-col h4 {
   line-height: 1.4;
   margin-bottom: 0;
}

.home-header .row .flex-col h4 span {
   display: block;
}

.home-header .get-height {
   height: 100vh;
}

.home-header .hanger {
   position: absolute;
   left: 0;
   top: 50%;
   transform: translateY(-50%);
   display: inline-block;
}

.home-header .hanger svg {
   width: auto;
   height: 6em;
}

.home-header .hanger p {
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   margin: 0;
   padding-right: 5em;
   padding-left: var(--gap-padding);
   line-height: 1.2;
}

.home-header .hanger p span {
   display: block;
}

.home-header .white-block {
   position: absolute;
   height: 30vh;
   width: 50vw;
   top: 115vh;
   right: 0;
   background: var(--color-white);
   display: none;
}

.home-header .hanger .digital-ball {
   z-index: 500;
   position: absolute;
   right: .9em;
   left: auto;
   top: .9em;
   width: 4.2em;
   height: 4.2em;
   transform: translate(0%, 0%);
   background: transparent;
}

.home-header .hanger .digital-ball .globe {
   width: 2.5em;
   height: 2.5em;
   font-size: .8em;
}


@media screen and (max-width: 720px){

   .home-header {
      min-height: 110vh;
      min-height: calc(var(--vh, 1vh) * 110);
      max-height: 110vh;
      align-items: flex-end;
      padding-top: calc(var(--gap-padding) * 2);
      padding-bottom: calc(var(--gap-padding) * 2);
   }

   .home-header .personal-image {
      top: -5%;
      height: 105%;
   }

   .home-header .big-name {
      position: absolute;
      bottom: calc(var(--vh, 1vh) * 33);
      left: 0;
      width: 100%;
      padding-bottom: calc(var(--gap-padding) * 1.25);
   }

   .home-header .row {
      justify-content: flex-start;
   }

   .home-header .container {
      padding-bottom: calc(var(--vh, 1vh) * 9);
   }

   .home-header .row .flex-col .header-above-h4 {
      top: calc(var(--vh, 1vh) * -8);
      position: absolute;
   }

   .home-header .hanger {
      width: 6em;
      height: 6em;
      left: auto;
      right: 0;
      top: calc(var(--vh, 1vh) * 100);
      transform: translate(0, -110%);
   }

   .home-header .hanger svg,
   .home-header .hanger p {
      display: none;
   }

   .home-header .hanger .digital-ball .globe {
      width: 3em;
      height: 3em;
   }
   
}


/* ------------------------- Home - Intro -------------------------------------------------- */

.home-intro {
   padding-bottom: calc(var(--section-padding) * .66);
}

.home-intro .flex-col:nth-child(1) {
   width: 70%;
   padding-right: var(--gap-padding);
}

.home-intro .flex-col:nth-child(2) {
   width: 30%;
   padding-left: var(--gap-padding);
}

.home-intro .flex-col:nth-child(2) .btn {
   position: absolute;
   top: 80%;
}

.home-intro .flex-col:nth-child(2) p {
   margin-bottom: 6em;
   padding-top: .5em;
   max-width: 14em;
}

@media screen and (max-width: 720px){

   .home-intro {
      padding-bottom: calc(var(--section-padding) * 1);
   }

   .home-intro .flex-col:nth-child(1) {
      width: 100%;
      padding-right: 0;
   }
   
   .home-intro .flex-col:nth-child(2) {
      width: 100%;
      padding-left: 0;
      max-width: 100%;
      padding: calc(var(--section-padding) / 2) 0 0 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
   }

   .home-intro .flex-col:nth-child(2) .text-wrap {
      width: 100%;
   }

   .home-intro .flex-col:nth-child(2) p {
      margin-bottom: calc(var(--section-padding) * .66);
      padding-top: 0;
   }

   .home-intro .flex-col:nth-child(2) .btn {
      position: absolute;
      top: unset;
      bottom: 0;
      right: 0;
      transform: translate(-20%, 50%);
   }
}

/* ------------------------- Wrap Work Grid -------------------------------------------------- */

.section-wrap-work {
   background: var(--color-white);
}

/* ------------------------- Work Grid -------------------------------------------------- */

.work-grid .row { 
   align-items: center;
   color: unset;
   text-decoration: unset;
   padding: calc(var(--section-padding) / 3) 0;
}

.work-grid .work-items li {
   transition: var(--animation-smooth);
}

.work-grid .work-items .flex-col:nth-child(1) {
   padding-left: calc(var(--container-padding) * 1);
   width: 44%;
}

.work-grid .work-items .flex-col:nth-child(2) {
   width: 20%;
   padding-top: .25em;
}

.work-grid .work-items .flex-col:nth-child(3) {
   width: 22%;
   padding-top: .25em;
}

.work-grid .work-items .flex-col:nth-child(4) {
   width: 14%;
   padding-top: .25em;
   padding-right: calc(var(--container-padding) * 1);
   text-align: right;
}

.work-grid .work-items h4 {
   position: relative;
   display: block;
   overflow: hidden;
   line-height: 1;
   transition: var(--animation-fast);
   transform: translateX(0) rotate(0.001deg);
}

.work-grid .work-items h4 span {
   position: relative;
   display: block;
   padding: .1em 0;
}

.work-grid .work-items a:hover h4 {
   transform: translateX(calc(var(--container-padding) * -.1)) rotate(0.001deg);
}

.work-grid .work-items a p {
   transition: var(--animation-fast);  
   transform: translateX(0) rotate(0.001deg);
}

.work-grid .work-items a:hover p {
   transform: translateX(calc(var(--container-padding) * .1)) rotate(0.001deg);
}

.work-grid .work-items li a {
   transition: var(--animation-fast);
}

.work-grid .work-items:hover a {
   opacity: 1;
}

.work-grid .work-items a:hover {
   opacity: .33;
}

/* ------------------------- Work Grid - Large -------------------------------------------------- */

.large-work-grid {
   padding-top: 0;
   padding-bottom: 0;
}

.large-work-grid .work-items h4 {
   font-size: calc(clamp(3.25em, 7vw, 8em) * .75);
}

.large-work-grid .row { 
   padding: calc(var(--section-padding) / 3.5) 0 calc(var(--section-padding) / 3) 0;
}

.large-work-grid .work-items .flex-col:nth-child(1) {
   padding-left: calc(var(--container-padding) * 1);
   width: 70%;
}

.large-work-grid .work-items .flex-col:nth-child(2) {
   width: 30%;
   padding-left: var(--gap-padding);
   padding-top: 1em;
   padding-right: calc(var(--container-padding) * .5);
}

@media screen and (max-width: 1024px) {
   .large-work-grid {
      display: none;
   }
}
/* ------------------------- Work Grid - Small -------------------------------------------------- */

.small-work-grid {
   padding-top: 0;
   padding-bottom: 0;
}

.small-work-grid .row {
   padding: 2em 0 2em 0;
}

.small-work-grid .work-items li {
   max-height: 0;
   overflow: hidden;
}

.small-work-grid .work-items li.visible {
   max-height: calc(clamp(1.75em, 2.3vw, 2.5em) + 4.4em);
}

.small-work-grid .work-items {
   padding-bottom: calc(var(--section-padding) * .5);
}

@media screen and (max-width: 1024px) {
   .small-work-grid {
      display: none;
   }
}

/* ------------------------- Work Grid - Tiny -------------------------------------------------- */

.tiny-work-grid {
   padding-top: 0;
   padding-bottom: 0;
}

.tiny-work-grid .row {
   padding: 2em 0 2em 0;
}

.tiny-work-grid .work-items {
   padding-bottom: calc(var(--section-padding) * .75);
}

.tiny-work-grid .arrow {
   position: absolute;
   right: var(--container-padding);
   transform: translateY(.2em);
   background: var(--color-dark);
   width: 2em;
   height: 2em;
   border-radius: 50%;
   display: none;
   align-content: center;
   justify-content: center;
}

.tiny-work-grid .arrow svg {
   width: 35%;
   transform: rotate(-90deg);
}

@media screen and (max-width: 1000px) {

   .archive-header h1 .count-nr {
      font-size: .45em;
   }

   .tiny-work-grid .container {
      padding: 0;
   }

   .tiny-work-grid .work-items a:hover {
      opacity: 1;
   }

   .tiny-work-grid .arrow {
      display: flex;
   }

   .tiny-work-grid .row {
      align-items: flex-start;
      padding: 2em 0 2.15em 0;
   }

   .tiny-work-grid .grid-sub-title .flex-col:nth-child(1) { display: none;}
   .tiny-work-grid .grid-sub-title .flex-col:nth-child(2) {width: 35% !important; order: 3;}
   .tiny-work-grid .grid-sub-title .flex-col:nth-child(3) {width: 52% !important; order: 2; padding-left: calc(var(--container-padding) * 1);}
   .tiny-work-grid .grid-sub-title .flex-col:nth-child(4) {width: 13% !important; order: 4;}

   .tiny-work-grid .work-items .flex-col:nth-child(1) {width: 100%; padding-bottom: .75em; order: 1;}
   .tiny-work-grid .work-items .flex-col:nth-child(2) {width: 35%; order: 3; padding-right: 1em;} 
   .tiny-work-grid .work-items .flex-col:nth-child(3) {width: 52%; order: 2; padding-right: 1em; padding-left: calc(var(--container-padding) * 1);} 
   .tiny-work-grid .work-items .flex-col:nth-child(4) {width: 13%; order: 4;} 

   .tiny-work-grid .work-items a:hover h4 {
      transform: translateX(0) rotate(0.001deg);
   }

   .tiny-work-grid .work-items a:hover p {
      transform: translateX(0) rotate(0.001deg);
   }

   .tiny-work-grid .work-items p {
      font-size: .9em;
   }

   .tiny-work-grid .stripe {
      opacity: .75;
   }
}

@media screen and (max-width: 450px) {

   .tiny-work-grid .row {
      align-items: flex-start;
      padding: 1.9em 0 1.9em 0;
   }

   .tiny-work-grid .work-items .flex-col:nth-child(1) {
      padding-bottom: .5em;
   }

   .tiny-work-grid .work-items h4 {
      font-size: 1.3em;
   }

   .tiny-work-grid .work-items p {
      font-size: .8em;
   }

   .tiny-work-grid .arrow {
      transform: translateY(-.2em);
   }
}

/* ------------------------- Work Grid - Sub Title Info -------------------------------------------------- */

.work-grid .grid-sub-title {
   width: 100%;
   display: flex;
   padding-bottom: var(--gap-padding);
}

.work-grid .grid-sub-title h5 {
   margin: 0;
   width: 100%;
}

.work-grid .grid-sub-title .flex-col:nth-child(1) {
   padding-left: calc(var(--container-padding) * 1);
   width: 44%;
   /* padding-left: var(--gap-padding); */
}

.work-grid .grid-sub-title .flex-col:nth-child(2) {
   width: 20%;
}

.work-grid .grid-sub-title .flex-col:nth-child(3) {
   width: 22%;
}

.work-grid .grid-sub-title .flex-col:nth-child(4) {
   width: 14%;
   padding-right: calc(var(--container-padding) * 1);
   text-align: right;
   /* padding-right: var(--gap-padding); */
}

/* ------------------------- Large Work Grid - Sub Title Info -------------------------------------------------- */


.large-work-grid .grid-sub-title .flex-col:nth-child(1) {
   padding-left: calc(var(--container-padding) * 1);
   width: 70%;
}

.large-work-grid .grid-sub-title .flex-col:nth-child(2) {
   width: 30%;
   padding-left: var(--gap-padding);
   padding-right: calc(var(--container-padding) * 1);
}


/* ------------------------- Work Grid - Filter Row -------------------------------------------------- */

.work-filters {
   margin-top: calc(var(--section-padding) * -.25);
   padding-top: 0;
   padding-bottom: calc(var(--section-padding) / 2);
}

.work-filters .filter-row {
   width: 100%;
   padding: 0 var(--container-padding) 0 var(--container-padding);
   padding: 0 calc(var(--container-padding) * .96) 0 calc(var(--container-padding) * .96);
   display: flex;
   justify-content: space-between;
}

.work-filters .filter-row .toggle-row {
   display: flex;
}

.work-filters .filter-row .toggle-row .btn {
   display: inline-flex;
   margin-right: .5em;
}

.work-filters .filter-row .grid-row {
   display: flex;
}

.work-filters .filter-row .grid-row .btn {
   display: inline-flex;
   margin-left: .5em;
}

@media screen and (max-width: 1024px) {
   .work-filters .filter-row .grid-row {
      display: none;
   }
}

@media screen and (max-width: 540px) {
   .work-filters .filter-row {
      padding: 0;
   }

   .work-filters .filter-row .toggle-row .btn .btn-text {
      margin-right: .15em;
   }
}

@media screen and (max-width: 460px) {
   .work-filters .filter-row .toggle-row .btn {
      font-size: .89em;
   }
}

@media screen and (max-width: 390px) {
   .work-filters .filter-row .toggle-row .btn {
      font-size: .83em;
   }
}

@media screen and (max-width: 380px) {
   .work-filters .filter-row .toggle-row .btn {
      font-size: .8em;
   }
}


/* ------------------------- Work Grid - After Center Btn -------------------------------------------------- */

.center-grid-btn {
   padding-top: calc(var(--section-padding) / 3);
   padding-bottom: calc(var(--section-padding) * 1);
}

.center-grid-btn-home {
   padding-top: calc(var(--section-padding) / 2);
   padding-bottom: 0;
}

.center-grid-btn-archive {
   padding-top: 0;
   padding-bottom: calc(var(--section-padding) * .75);
}

.center-grid-btn .grid-after-btn {
   display: flex;
   justify-content: center;
}

.center-grid-btn .grid-after-btn .btn-text {
   min-width: 10em;
}

@media screen and (max-width: 720px) {

   /* .center-grid-btn .btn,
   .center-grid-btn .btn-click {
      width: 100%;
   } */

}
/* ------------------------- Work Tiles -------------------------------------------------- */

.work-tiles {
   padding-top: 0;
}

.work-tiles .container {
   padding-left: calc(var(--container-padding) * .75);
   padding-right: calc(var(--container-padding) * .75);
}

.work-tiles ul {
   width: 100%;
   display: flex;
   flex-wrap: wrap;
   position: relative;
   padding-top: var(--gap-padding);
}

.work-tiles ul li {
   display: block;
   width: 50%;
   position: relative;
   overflow: hidden;
   height: 0;
   width: 0;
   transform: translateY(0%) rotate(0.001deg) scale(1);
   transition: opacity .3s ease-in .4s, transform 1s cubic-bezier(0.16, 1, 0.3, 1);
}

.work-tiles ul li.visible {
   height: auto;
   width: 50%;
}

.work-tiles ul li.tile-fade-out {
   opacity: 0;
   transform: translateY(5em) rotate(0.001deg);
   transition: opacity .25s ease-out, transform 0s linear .3s;
}

.work-tiles ul li.tile-fade-in {
   opacity: 1;
   transform: translateY(5em) rotate(0.001deg) scale(1);
}

.work-tiles ul li .single-tile-wrap {
   width: 100%;
   padding-bottom: calc(var(--section-padding) * 1);
   padding-left: calc(var(--container-padding) * .25);
   padding-right: calc(var(--container-padding) * .25);
}

.work-tiles ul li a { 
   align-items: center;
   color: unset;
   text-decoration: unset;
   overflow: hidden;
}

.work-tiles ul li a .flex-col:nth-child(1) {
   width: 100%;
   padding-bottom: var(--gap-padding);
}

.work-tiles ul li a .flex-col:nth-child(2) {
   width: 100%;
   padding-bottom: calc(var(--gap-padding) / 2);
}

.work-tiles ul li a .stripe {
   margin-top: calc(var(--gap-padding) / 2);
}

.work-tiles ul li a .flex-col:nth-child(3) { 
   width: 70%;
}

.work-tiles ul li a .flex-col:nth-child(4) {
   width: 30%;
   text-align: right;
}

.work-tiles ul li a .tile-image {
   width: 100%;
   position: relative;
   background: var(--color-lightgray);
   overflow: hidden;
}

.work-tiles ul li a .tile-image .overlay-image { 
   transform: scale(1) rotate(0.001deg);
   transition: var(--animation-primary);
    /*will-change: transform;*/
}

.work-tiles ul li a:hover .tile-image .overlay-image { 
   transform: scale(1.025) rotate(0.001deg);
}

.work-tiles ul li a .tile-image::before {
   display: block;
   content: "";
   padding-top: 100%;
}

.work-tiles ul li a h4 {
   position: relative;
   display: block;
   overflow: hidden;
   line-height: 1;
   transition: var(--animation-fast);
   transform: translateX(0) rotate(0.001deg);
   margin: 0;
}

.work-tiles ul li a h4 span {
   position: relative;
   display: block;
   padding: .1em 0;
}

@media screen and (max-width: 1024px) {
   .work-tiles ul li a h4 {
      font-size: calc(clamp(1.75em, 2.3vw, 2.5em) * 1.125);
   }
}

@media screen and (max-width: 620px) {

   .work-tiles ul li.visible {
      width: 100%;
   }

   .work-tiles .container {
      padding-left: var(--container-padding);
      padding-right: var(--container-padding);
   }

   .work-tiles ul li .single-tile-wrap {
      padding-left: 0;
      padding-right: 0;
   }

   .work-tiles ul li a:hover .tile-image .overlay-image { 
      transform: scale(1) rotate(0.001deg);
   }

   .work-tiles ul li a .flex-col:nth-child(2) {
      width: 100%;
      padding-bottom: calc(var(--section-padding) / 6);
   }
   
   .work-tiles ul li a .stripe {
      margin-top: calc(var(--section-padding) / 6);
   }
}

/* ------------------------- Work Tiles - Home -------------------------------------------------- */

.work-tiles-home {
   display: none;
   padding-bottom: 0;
}

@media screen and (max-width: 1024px) {
   .work-tiles-home {
      display: block;
   }

   .work-tiles-home + .center-grid-btn {
      padding-top: 0;
   }

   .work-tiles-home .container ul li:nth-child(3){
      display: none;
   }

   .work-tiles-home .container ul li:nth-child(4){
      display: none;
   }
}

/* ------------------------- Work Grid - Fade -------------------------------------------------- */


.grid-fade {
   height: 0;
   overflow: hidden;
   transform: translateY(0%) rotate(0.001deg) scale(1);
   transition: opacity .3s ease-in .4s, transform 1s cubic-bezier(0.16, 1, 0.3, 1);
   padding: 0;
}

.grid-fade.visible {
   height: auto;
}

.grid-fade.grid-fade-out {
   opacity: 0;
   transform: translateY(5em) rotate(0.001deg);
   transition: opacity .25s ease-out, transform 0s linear .3s;
}

.grid-fade.grid-fade-in {
   opacity: 1;
   transform: translateY(5em) rotate(0.001deg) scale(1);
}

@media screen and (max-width: 1024px) {
   .grid-fade {
      height: auto;
   }
}


/* ------------------------- Mouse Position Image -------------------------------------------------- */

.mouse-pos-list-image {
   width: 0;
   z-index: 25;
   position: fixed;
   pointer-events: none;
   transform: translate(-50%, -52%);
   display: block;
   left: 55%;
   overflow: hidden;
   transition: width .4s cubic-bezier(0.36, 0, 0.66, 0);
   left: 0%;
   will-change: transform, width;
}

.mouse-pos-list-image.active {
   border-radius: 0;
   width: clamp(10em, 27.5vw, 25em);
   transition: width .4s cubic-bezier(0.34, 1, 0.64, 1);
}

.mouse-pos-list-image-bounce {
   overflow: hidden;
   transform: scale(1) rotate(.001deg);
   transition: all .5s cubic-bezier(0.65, 0, 0.35, 1);
    /*will-change: transform;*/
}

.mouse-pos-list-image-bounce.active {
   transform: scale(1) rotate(.001deg);
}

.mouse-pos-list-image::before {
   display: block;
   content: "";
   padding-top: 100%;
}

.mouse-pos-list-image .mouse-pos-list-image-inner {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
} 

.mouse-pos-list-image .float-image-wrap {
   width: 100%;
   position: absolute;
   left: 0;
   top: 0;
   display: block;
    /*will-change: transform;*/
}

.mouse-pos-list-image .float-image-wrap .mouse-pos-list-image-inner {
   position: relative;
   width: 100%;
   display: block;
   overflow: hidden;
}

.mouse-pos-list-image .float-image-wrap .mouse-pos-list-image-inner:before {
   display: block;
   content: "";
   padding-top: 100%;
} 

.mouse-pos-list-image .float-image-wrap .mouse-pos-list-image-inner {
   width: 0;
}

.mouse-pos-list-image .float-image-wrap .mouse-pos-list-image-inner.visible {
   width: 100%;
}

@media screen and (max-width: 540px) {

   .mouse-pos-list-image {
      display: none;
   }
}

/* ------------------------- Mouse Position Button -------------------------------------------------- */


.mouse-pos-list-btn,
.mouse-pos-list-span {
   width: 0;
   height: 0;
   border-radius: 50%;
   border: 0;
   background: var(--color-blue);
   position: fixed;
   z-index: 26;
   pointer-events: none;
   transform: translate(-50%, -60%);
   overflow: hidden;
   display: block;
   transition: width .4s cubic-bezier(0.36, 0, 0.66, 0), height .4s cubic-bezier(0.36, 0, 0.66, 0);
   left: 50.5%;
   top: -10%;
   left: -10%;
   left: 0%;
   top: 0%;
   will-change: transform, width, height;
}

.mouse-pos-list-span {
   background: transparent;
   color: var(--color-white);
   display: flex;
   justify-content: center;
   align-items: center;
   left: 49.75%;
   top: -11.25%;
   left: -11.25%;
   left: 0%;
   top: 0%;
}

.mouse-pos-list-span p {
   margin: 0;
}

.mouse-pos-list-span .arrow,
.btn .arrow {
   width: 0em;
   transform: rotate(-90deg) translateX(-.05em);
   margin-left: .33em;
   display: inline-flex;
   position: relative;
   transition: width .4s cubic-bezier(0.36, 0, 0.66, 0);
   will-change: width;
}

.mouse-pos-list-span.active-big .arrow,
.btn .arrow {
   width: .66em;
   transition: width .4s cubic-bezier(0.34, 1, 0.64, 1)
}

.mouse-pos-list-btn.active,
.mouse-pos-list-span.active {
   width: clamp(3.75em, 5.5vw, 5em);
   height: clamp(3.75em, 5.5vw, 5em);
   transition: width .4s cubic-bezier(0.34, 1, 0.64, 1), height .4s cubic-bezier(0.34, 1, 0.64, 1);
}

.mouse-pos-list-btn.active-big,
.mouse-pos-list-span.active-big {
   width: calc(clamp(3.75em, 5.5vw, 5em) * 1.66);
   height: calc(clamp(3.75em, 5.5vw, 5em) * 1.66);
   transition: width .4s cubic-bezier(0.34, 1, 0.64, 1), height .4s cubic-bezier(0.34, 1, 0.64, 1);
}

.mouse-pos-list-btn.active-big.hover,
.mouse-pos-list-span.active-big.hover {
   width: calc(clamp(3.75em, 5.5vw, 5em) * 1.45);
   height: calc(clamp(3.75em, 5.5vw, 5em) * 1.45);
}

.mouse-pos-list-btn.active.pressed,
.mouse-pos-list-span.active.pressed {
   height: calc(clamp(3.75em, 5.5vw, 8em) * 1.4);
   width: calc(clamp(3.75em, 5.5vw, 8em) * 1.4);
}

.mouse-pos-list-btn.active-big.pressed,
.mouse-pos-list-span.active-big.pressed {
   height: calc(clamp(3.75em, 5.5vw, 8em) * 2);
   width: calc(clamp(3.75em, 5.5vw, 8em) * 2);
}

.mouse-pos-list-span.active,
.mouse-pos-list-span.active-big {
   border-radius: 0;
   width: clamp(10em, 27.5vw, 25em);
   transition: width .4s cubic-bezier(0.34, 1, 0.64, 1), height .4s cubic-bezier(0.34, 1, 0.64, 1);
}

.mouse-pos-list-span p {
   font-size: 0em;
   transition: font-size .4s cubic-bezier(0.36, 0, 0.66, 0);
   will-change: font-size;
}

.mouse-pos-list-span.active p,
.mouse-pos-list-span.active-big p {
   font-size: 1em;
   transition: font-size .4s cubic-bezier(0.34, 1, 0.64, 1);
}

.load-icon {
   width: 3em;
   height: 3em;
   border-radius: 50%;
   border: 0;
   background: var(--color-blue);
   position: fixed;
   pointer-events: none;
   transform: scale(0);
   overflow: hidden;
   display: block;
   right: var(--gap-padding);
   bottom: var(--gap-padding);
   z-index: 2000;
}

.load-icon svg {
   left: 25%;
   top: 25%;
   position: absolute;
   width: 50%;
   transform: rotate(0.0001deg);
   animation: rotateLoad 1s linear infinite;
}

@keyframes rotateLoad {
   0%   {transform: rotate(0.0001deg);}
   100% {transform: rotate(360deg);}
}


@media screen and (max-width: 540px) {

   .mouse-pos-list-btn,
   .mouse-pos-list-span {
      display: none;
   }
}

/* ------------------------- About - Header -------------------------------------------------- */

.about-header {
   background: var(--color-white);
}

/* ------------------------- About - Stripe + Globe -------------------------------------------------- */

.line-globe {
   overflow: visible;
   z-index: 5;
}

@keyframes digitalball {
   0%   {background: var(--color-blue);}
   50%   {background: #ff4444;}
   100% {background: var(--color-blue);}
}

.digital-ball {
   width: clamp(6em, 12vw, 11em);
   height: clamp(6em, 12vw, 11em);
   background: var(--color-dark);
   border-radius: 50%;
   top: 0;
   right: 0;
   transform: translate(-50%, -50%);
   position: absolute;
   overflow: hidden;
   background: var(--color-blue);
}

.digital-ball .overlay {
   opacity: 0;
   transition: opacity 1s ease-in-out;
   animation: digitalball 6s ease-in-out infinite;
}

.line-globe .digital-ball:hover .overlay {
   opacity: 1;
}

@keyframes globeBounce {
   0%   {transform: translate(-50%, -40%);}
   50%  {transform: translate(-50%, -60%);}
   100% {transform: translate(-50%, -40%);}
}

.globe {
   position: absolute;
   top: 50%;
   left: 50%;
   width: clamp(1.7em, 12vw, 3.1em);
   height: clamp(1.7em, 12vw, 3.1em);
   transform: translate(-50%, -50%);
   border-radius: 50%;
   overflow: hidden;
    /*will-change: transform;*/
}

@keyframes globe {
   0%   {transform: translate(-50%, -50%) rotate(15deg); }
   50%   {transform: translate(-50%, -50%) rotate(-15deg);}
   100% {transform: translate(-50%, -50%) rotate(15deg);}
}

.globe-wrap {
   top: 50%;
   left: 50%;
   width: 100%;
   height: 100%;
   position: absolute;
   display: block;
   border-radius: 50%;
   transform: translate(-50%, -50%) rotate(30deg);
   animation: globe 5.4s cubic-bezier(0.35, 0, 0.65, 1) infinite;
   overflow: hidden;
   box-shadow: inset 0px 0px 0px .125em var(--color-white);
}

.globe .circle {
   position: absolute;
   top: 50%;
   left: 50%;
   width: 100%;
   height: 100%;
   transform: translate(-50%, -50%);
   border-radius: 50%;
   box-shadow: inset .1em 0px 0px .08em var(--color-white);
   animation: circle1 2.7s linear infinite;
   font-size: .75em;
}

@keyframes circle1 {
   0%   {border-radius: 50%; box-shadow: inset .1em 0px 0px .08em var(--color-white); width: 100%; }
   49%  {border-radius: 50%; box-shadow: inset .1em 0px 0px .08em var(--color-white); background: transparent;}
   50%  {border-radius: 0%; width: .175em; background: var(--color-white);}
   51%  {border-radius: 50%; box-shadow: inset -.1em 0px 0px .08em var(--color-white); background: transparent;}
   100% {border-radius: 50%; box-shadow: inset -.1em 0px 0px .08em var(--color-white); width: 100%;}
}

.globe :nth-child(1) {
   animation-delay: -1.8s;
}

.globe :nth-child(2) {
   animation-delay: -.9s;
}


.globe .circle-hor {
   position: absolute;
   top: 50%;
   left: 50%;
   width: 150%;
   transform: translate(-50%, -50%);
   border-radius: 50%;
   height: 55%;
   box-shadow: inset 0px 0px 0px .15em var(--color-white);
   font-size: .75em;
}

.globe .circle-hor-middle {
   position: absolute;
   top: 50%;
   left: 50%;
   width: 150%;
   transform: translate(-50%, -50%);
   border-radius: 0%;
   height: .15em;
   background: var(--color-white);
   font-size: .75em;
}

@media screen and (max-width: 1000px) {

   .digital-ball {
      transform: translate(-20%, -50%);
   }

}

/* ------------------------- About - Image -------------------------------------------------- */

.about-image {
   padding-top: calc(var(--section-padding) * .75);
   padding-bottom: 0;
   background: var(--color-white);
}

.about-image .bottom-lightgray {
   position: absolute;
   bottom: 0;
   width: 100%;
   height: var(--section-padding);
   left: 0;
   background: var(--color-lightgray);
   height: 10vw;
   display: none;
}

.about-image .flex-col:nth-child(1) {
   width: 35%;
   padding-left: var(--container-padding);
   padding-right: calc(var(--container-padding) / 2);
}

.about-image .flex-col:nth-child(1) p {
   margin-top: -.33em;
   display: block;
    /*will-change: transform;*/
}

.about-image .flex-col:nth-child(1) .arrow {
   position: absolute;
   left: 0;
   transform: rotate(-45deg);
    /*will-change: transform;*/
}

.about-image .flex-col:nth-child(1) .arrow svg g{
   stroke: var(--color-dark);
}

.about-image .flex-col:nth-child(2) {
   width: 65%;
}

.single-about-image {
   width: 100%;
   display: block;
   background: var(--color-lightgray);
   overflow: hidden;
   position: relative;
}

.single-about-image::before{
   content: "";
   display: block;
   padding-top: 135%;
}

.single-about-image .overlay:nth-child(1) {
   background: url('../assets/images/myPicture2min.jpg') center center no-repeat;
   background-size: cover;
   top: -20vh;
   height: 105%;
}

.single-about-image .overlay:nth-child(2) {
   background: linear-gradient(to bottom,hsla(220, 13%, 5%, .075) 0%, hsla(220, 13%, 5%, 0) 50%);
   display: none;
}

@media screen and (max-width: 840px) {
   .about-image .flex-col:nth-child(1) {
      width: 45%;
      padding-right: calc(var(--container-padding) / 1);
   }
   
   .about-image .flex-col:nth-child(2) {
      width: 55%;
   }

   .single-about-image::before{
      padding-top: 125%;
   }
}

@media screen and (max-width: 840px) {

   .about-image {
      padding-top: calc(var(--section-padding) * .85);
   }

   .about-image .flex-col:nth-child(1) {
      width: unset;
      padding: 0;
      margin-left: calc(var(--container-padding) / 1);
      padding-right: calc(var(--container-padding) / 1);
      padding-top: calc(var(--section-padding) / 4);
      width: 100%;
   }

   .about-image .flex-col:nth-child(1) p {
      max-width: 100%;
   }
   
   .about-image .flex-col:nth-child(2) {
      padding-top: calc(var(--section-padding) / 1.15);
      width: 100%;
   }

   .about-image .flex-col:nth-child(1) .arrow {
      top: calc(var(--section-padding) / -4);
      transform: rotate(0deg) translateY(-50%);
   }

   .about-image .bottom-lightgray {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: var(--section-padding);
      left: 0;
      background: var(--color-lightgray);
      height: 60vw;
      display: block;
   }
}

@media screen and (max-width: 540px) {

   .single-about-image::before {
      padding-top: 125%;
      padding-top: 140%;
   }

   .single-about-image .overlay:nth-child(1) {
      width: 130%;
      left: -30%;

      width: 100%;
      left: 0;
      top: -18%;
      height: 120%;
   }

   .about-image .flex-col:nth-child(1) {
      margin-left: 0;
      padding-right: 0;
   }

   .about-image .flex-col:nth-child(2) {
      padding-top: calc(var(--section-padding) * .5);
   }

}


/* ------------------------- About - Services -------------------------------------------------- */

.about-services {
   padding-top: calc(var(--section-padding) * .75);
   background: var(--color-lightgray);
   margin-top: -1px;
}

.about-services .row:nth-child(1) {
   padding-bottom: calc(var(--section-padding) / 2);
}

.about-services .row:nth-child(2) {
   width: calc(100% + calc(var(--container-padding) / 1.5));
   margin-left: calc(var(--container-padding) / -1.5);
}

.about-services .row:nth-child(2) .flex-col {
   width: 33.333%;
   padding-left: calc(var(--container-padding) / 1.5);
}

.about-services .row:nth-child(2) .flex-col h5:nth-child(1) {
   padding-bottom: 2em;
}

.about-services .row:nth-child(2) .flex-col h5 {
   margin: 0;
   font-size: .8em;
}

.about-services .row:nth-child(2) .flex-col h4 {
   margin: 0;
}

.about-services .row:nth-child(2) .flex-col .stripe {
   margin-bottom: 2em;
}

.about-services .row:nth-child(2) .flex-col svg {
   width: 1em;
   padding: 0;
   margin-right: .25em;
   transform: translate(0, -.05em);
}

.about-services .row:nth-child(2) .flex-col svg path {
   fill: var(--color-dark);
}

.about-services .row:nth-child(2) .flex-col p:nth-child(4) {
   padding-top: 2em;
}

.animate-dot {
   opacity: 0;
   animation: animateDot1 2s linear infinite;
}

.animate-dot:nth-child(2) {
   animation: animateDot2 2s linear infinite;
}

.animate-dot:nth-child(3) {
   animation: animateDot3 2s linear infinite;
}

@keyframes animateDot1 {
   0%   {opacity: 0; }
   20%  {opacity: 0;}
   30%  {opacity: 1;}
   90% {opacity: 1;}
}

@keyframes animateDot2 {
   0%   {opacity: 0; }
   35%  {opacity: 0;}
   45%  {opacity: 1;}
   90% {opacity: 1;}
}

@keyframes animateDot3 {
   0%   {opacity: 0; }
   50%  {opacity: 0;}
   60%  {opacity: 1;}
   90% {opacity: 1;}
}

@media screen and (max-width: 720px) {
   
   .about-services {
      padding-top: calc(var(--section-padding) * 1);
   }

   .about-services .row:nth-child(2) {
      width: 100%;
      margin-left: 0;
   }

   .about-services .row:nth-child(2) .flex-col h5:nth-child(1) {
      display: none;
   }
   
   .about-services .row:nth-child(2) .flex-col {
      width: 100%;
      padding-left: 0;
      padding-bottom: calc(var(--section-padding) * .5);
   }

   .about-services .row:nth-child(2) .flex-col:last-child {
      padding-bottom: 0;
   }

   .about-services .row:nth-child(2) .flex-col .stripe {
      margin-bottom: calc(var(--section-padding) * .5);
   }
   
   .about-services .row:nth-child(2) .flex-col p:nth-child(4) {
      padding-top: calc(var(--gap-padding) * .5);
   }
}


/* ------------------------- About - Split Awwwards -------------------------------------------------- */


.about-awwwards .row {
   align-items: center;
}

.about-awwwards .flex-col:nth-child(1) {
   width: 50%;
   padding-right: calc(var(--container-padding) / 2);
}

.about-awwwards .flex-col:nth-child(2) {
   width: 50%;
   padding-left: calc(var(--container-padding) / 2);
   padding-bottom: 3em;
}

.about-awwwards .single-image {
   width: 100%;
   display: block;
   background: var(--color-lightgray);
   overflow: hidden;
   position: relative;
}

.about-awwwards .single-image::before{
   content: "";
   display: block;
   padding-top: 135%;
   padding-top: 125%;
}

.about-awwwards .single-image  .overlay:nth-child(1) {
   /* background: url("../img/DSC07292.jpg") center center no-repeat;
   background-size: cover;
   height: 130%;
   top: -25%;
   width: 105%;
   left: -5%; */

   background: url("../assets/images/myPicture1min.jpg") center center no-repeat;
   background-size: cover;
   top: -3%;
   height: 106%;
   width: 140%;
   left: -40%;

   /* background: url("../img/Kien_Team-16.jpg") center center no-repeat;
   background-size: cover;
   top: -3%;
   height: 106%;
   width: 140%;
   left: -14%; */
}

.about-awwwards .single-image  .overlay:nth-child(2) {
   background: linear-gradient(to bottom,hsla(220, 13%, 5%, .075) 0%, hsla(220, 13%, 5%, 0) 50%);
}

.about-awwwards .awwwards-badge {
   width: 6em;
   position: relative;
   margin-bottom: calc(var(--section-padding) / 4);
   border-radius: 50%;
}

.about-awwwards .awwwards-badge::before {
   display: block;
   content: "";
   padding-top: 100%;
}

.about-awwwards .awwwards-badge svg:nth-child(1) {
   width: 100%;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}

.about-awwwards .awwwards-badge svg:nth-child(1) g {
   fill: var(--color-gray);
}

.about-awwwards .awwwards-badge svg:nth-child(2) {
   width: 40%;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}

.about-awwwards .awwwards-badge svg:nth-child(2) path {
   fill: var(--color-gray);
}

@media screen and (max-width: 720px) {

   .about-awwwards {
      padding-top: calc(var(--section-padding) / 1.5);
   }
   .about-awwwards .flex-col:nth-child(1) {
      width: 100%;
      padding-right: 0;
   }
   
   .about-awwwards .flex-col:nth-child(2) {
      width: 100%;
      padding-left: 0;
      padding-bottom: calc(var(--section-padding) / 2);
      order: 1;
   }

   .about-awwwards .single-image::before{
      padding-top: 125%;
   }
   
   .about-awwwards .single-image  .overlay:nth-child(1) {
      background: url("../assets/images/myPicture1min.jpg") center center no-repeat;
      background-size: cover;
      top: -8%;
      height: 110%;
      width: 150%;
      left: -50%;
   }
}


/* ------------------------- FAQ - Section -------------------------------------------------- */


.faq {
   padding-top: 0;
}

.faq .flex-col:nth-child(1) {
   padding: 0 calc(var(--container-padding) / 2);
   padding-bottom: calc(var(--section-padding) / 2);
}

.faq ul {
   width: 100%;
}

.faq li {
   padding: 0 calc(var(--container-padding) / 2);
   border-top: 1px solid var(--color-border);
}

.faq li a {
   padding: 2em 0 2em 0;
   margin: 0;
   background: transparent !important;
   position: relative;
   border-radius: 0 !important;
}

.faq li h4 {
   margin: 0;
}

.faq .uk-accordion-title {
   font-size: unset;
   line-height: unset;
}

.faq .uk-accordion>:nth-child(n+2) {
   margin-top: 0;
}

.faq .uk-accordion>:last-child {
   border-bottom: 1px solid var(--color-border);
}

.faq .uk-accordion-content {
   margin-top: -.5em;
   padding-bottom: 2.5em;
   padding-right: calc(var(--container-padding) / 2);
}

.faq .uk-accordion-title:hover {
   color: var(--color-dark);
}

.faq .uk-accordion-title::before {
   display: none;
}

.faq .plus {
   position: absolute;
   right: 0;
   top: 50%;
   transform: translateY(-50%) rotate(0.001deg);
   width: 2em;
   height: 2em;
   transition: var(--animation-fast);
}

.faq .plus::before,
.faq .plus::after {
   content: "";
   position: absolute;
   width: 60%;
   height: 2px;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%) rotate(0.001deg);
   background: var(--color-dark);
   transition: var(--animation-fast);
}

.faq .plus::after {
   transform: translate(-50%, -50%) scale(1, 1) rotate(90deg);
}

.faq li a:hover .plus {
   opacity: .5;
}

.faq li.uk-open a .plus::after {
   transform: translate(-50%, -50%) scale(1, 0) rotate(90deg);
}

@media screen and (max-width: 720px) {

   .faq .flex-col:nth-child(1) {
      padding: 0;
      padding-bottom: calc(var(--section-padding) / 3);
   }
   
   .faq li {
      padding: 0;
      border-top: 1px solid var(--color-border);
   }

   .faq .uk-accordion-content {
      padding-right: 0;
   }
}

/* ------------------------- Contact - Header -------------------------------------------------- */

.contact-header {
   background: var(--color-dark);
   padding-bottom: calc(var(--section-padding) / 3);
}

.contact-header .row .flex-col:nth-child(1) {
   width: 73%;
   padding-right: calc(var(--container-padding) / 2);
}

.contact-header .row:nth-child(1) .flex-col:nth-child(2) {
   padding-top: calc(clamp(3.25em, 7vw, 8em) * .875 * 1.175);
}
.bottom-footer .row:nth-child(1) .flex-col:nth-child(2) {
   padding-top: unset !important;
}
.contact-header .row:nth-child(1) .flex-col:nth-child(2) .arrow {
   position: absolute;
   bottom: calc(var(--section-padding) * -.5);
   transform: translateY(-50%);
}

.contact-header .row .flex-col:nth-child(2) {
   width: 27%;
   padding-left: calc(var(--container-padding) / 2);
}

.contact-header .row:nth-child(2) {
   padding-top: var(--section-padding);
}

.contact-header .row:nth-child(2) .links-wrap {
   width: 100%;
   flex-direction: column;
   align-items: flex-start;
   padding-bottom: 2.5em;
}

.contact-header .row:nth-child(2) .links-wrap li {
   height: 2em;
   display: flex;
   align-items: center;
}

.contact-header .row:nth-child(2) .links-wrap .btn-link.btn-link-external .btn-click {
   margin: 0;
   height: 2em;
}

.contact-header .row .flex-col:nth-child(1) .profile-picture {
   margin-right: .125em;
   height: .85em;
   width: .85em;
   transform: translateY(.09em);
   /* display: none; */
}

.contact-header .row .flex-col:nth-child(1) h1 span {
   display: block;
}

.contact-header .form {
   background: #0e031e;
   /* background: var(--color-dark); */
}

@media screen and (max-width: 720px){

   .contact-header .row .flex-col:nth-child(1) {
      width: 100%;
      padding-right: 0;
   }

   .contact-header .row .flex-col:nth-child(2) {
      width: 100%;
      padding-left: 0;
   }

   .contact-header .row .flex-col:nth-child(1) .profile-picture {
      display: inline-flex;
      margin-right: .125em; 
      height: .95em;
      width: .95em;
      transform: translateY(.1em);
   }

   .contact-header .row:nth-child(1) .flex-col:nth-child(2) .profile-picture {
      display: none;
   }

   .contact-header .form-col {
      padding: 0 0 0 1.5em;
      font-size: 1.15em;
   }

   .contact-header .row:nth-child(2) .flex-col:nth-child(2) h5:last-of-type,
   .contact-header .row:nth-child(2) .flex-col:nth-child(2) ul:last-of-type {
      display: none;
   }

   .contact-header .row:nth-child(2) {
      padding-top: 0;
   }

   .contact-header .row:nth-child(2) .flex-col:nth-child(2) {
      order: 1;
   }

   .contact-header .row:nth-child(1) .flex-col:nth-child(2) {
      height: calc(var(--section-padding) * .75 );
   }

   .contact-header .row:nth-child(1) .flex-col:nth-child(2) .arrow {
      left: unset;
      bottom: 0;
      top: -4em;
      right: .5em;
      transform: translateY(-50%) scale(-1, 1);
   }

   .contact-header .row:nth-child(2) .flex-col:nth-child(1) {
      padding-top: calc(var(--section-padding) * .25);
   }
}

/* ------------------------- Archive - Header -------------------------------------------------- */

#archive,
.archive-header,
.archive-work-grid,
.archive-work-grid + .footer-rounded-div .rounded-div {
   background: var(--color-lightgray);
}

.archive-header h1 .count-nr {
   color: var(--color-dark);
   opacity: 1;
   font-size: .3em;
}

/* ------------------------- Error - Header -------------------------------------------------- */

.error-header .overlay-video {
   overflow: hidden;
}

.error-header .overlay-dark {
   background: var(--color-dark-dark);
   opacity: .7;
}

.error-header h1 {
   margin-bottom: 0;
}

.error-header p {
   position: absolute;
   right: 0;
   top: 1em;
}

.error-header .stripe {
   margin-top: calc(var(--section-padding) * .66);
}

.error-header .container {
   width: 100%;
}

.section.full-height.error-header {
   display: block;
   align-items: unset;
}

.error-header .container .btn-row {
   position: relative;
}

.error-header .container .btn {
   position: absolute;
   right: 0;
   top: 0;
   transform: translate(-50%, -50%);
}

#error footer,
#default footer,
#success footer {
   transform: translateY(-100%);
   position: absolute;
   z-index: 5;
   bottom: 0;
   background: transparent;
}

#error .footer-contact,
#default .footer-contact,
#success .footer-contact {
   box-shadow: none;
}

@media screen and (max-width: 720px){
   .error-header p {
      position: relative;
      right: unset;
      top: unset;
      margin-top: calc(var(--gap-padding) * 1.5);
      margin-bottom: var(--section-padding);
   }

   .error-header h1 span {
      display: inline-flex;
   }

   #error footer, 
   #default footer, 
   #success footer {
      transform: translateY(0);
   }

   #error footer .socials,
   #default footer .socials,
   #success footer .socials {
      display: none;
   }

   .error-header .container .btn {
      transform: translate(-20%, -50%);
   }
}

/* ------------------------- Home - Horizontal Items -------------------------------------------------- */

.horizontal-items {
   overflow: hidden;
   padding-top: calc(var(--section-padding) * .75);
   padding-bottom: calc(var(--section-padding) - 2.5vw);
}

.horizontal-items .container {
   padding: 0;
   transform: rotate(0deg);
}

.horizontal-items .row {
   width: 120vw;
   left: -10vw;
    /*will-change: transform;*/
}

.horizontal-items .flex-col {
   width: 25%;
   padding: calc(var(--gap-padding) / 2);
   padding: 1.25vw;
    /*will-change: transform;*/
}

.horizontal-items .horizontal-single-item {
   position: relative;
}

.horizontal-items .horizontal-single-item::before {
   display: block;
   content: "";
   padding-top: 75%;
}

@media screen and (max-width: 1000px){
   .horizontal-items .flex-col {
      width: 33.333%;
   }

   .horizontal-items .flex-col:nth-child(4) {
      display: none;
   }

}

@media screen and (max-width: 540px){
   .horizontal-items {
      padding-top: calc(var(--section-padding) * 1);
   }

   .horizontal-items .flex-col {
      width: 50%;
      padding: 2.5vw;
   }

   .horizontal-items .flex-col:nth-child(4) {
      display: block;
   }

   .horizontal-items .row {
      width: 120vw;
   }

   .horizontal-items .row.row-1,
   .horizontal-items .row.row-2 {
      transform: translate(0,0) !important;
   }

   .horizontal-items .row-1 .flex-col:nth-child(3), 
   .horizontal-items .row-1 .flex-col:nth-child(4) {
      transform: translate(-20vw,0);
   }

   .horizontal-items .row-2 .flex-col:nth-child(3),
   .horizontal-items .row-2 .flex-col:nth-child(4) {
      display: none;
   }
   
   .horizontal-items .container {
      display: none;
      opacity: 0;
      visibility: hidden;
   }

   .horizontal-items {
      padding-top: 0;
   }
}


/* ------------------------- Case - Header -------------------------------------------------- */

.case-top-wrap {
   padding: 0;
}

.case-top-wrap.theme-dark {
   background: var(--color-dark);
}

.case-header {
   padding-bottom: 0;
}

.case-header h1 {
   font-size: calc(clamp(3.25em, 7vw, 8em) * 1);
}

.case-header p {
   padding-bottom: 0;
   opacity: .5;
   transform: translateY(-1em);
}


/* ------------------------- Case - Intro -------------------------------------------------- */

.case-intro {
   padding-top: calc(var(--section-padding) / 1.75);
   padding-bottom: calc(var(--section-padding) / 1.25);
   margin-top: -1px;
}

.case-intro .row .flex-col {
   width: calc(33.333% - (var(--gap-padding) / 1));
   margin-right: calc(var(--gap-padding) * 1.5);
}

.case-intro .row .flex-col:last-child {
   margin-right: 0;
}

.case-intro .row .flex-col:last-child li {
   display: inline-block;
}

.case-intro .row .flex-col .stripe {
   margin: 1.75em 0 1.5em 0;
}

@media screen and (max-width: 720px) {
   
   .case-intro {
      padding-top: calc(var(--section-padding) / 2.5);
      padding-bottom: calc(var(--section-padding) / 1.25);
   }

   .case-intro .row .flex-col {
      width: 100%;
      margin-right: 0;
      margin-bottom: 8vw;
   }

   .case-intro .row .flex-col:last-child {
      margin-bottom: 0;
   }

   .case-intro .row .flex-col .stripe {
      margin: 4.5vw 0 3vw 0;
   }
}

/* ------------------------- Case - Intro Image -------------------------------------------------- */

.case-intro-image {
   padding-top: 0;
   padding-bottom: 0;
   margin-top: -1px;
}

.case-intro-image.block-padding-bottom {
   padding-bottom: var(--section-padding);
}

.case-intro-image .btn-wrap {
   position: absolute;
   top: 0;
   right: 0;
   transform: translate(-50%, -50%);
   z-index: 2;
   display: block;
}

.case-intro-image .single-image {
   width: 100%;
   background: rgba(100, 100, 100, 0.1);
   overflow: hidden;
   position: relative;
}

.case-intro-image .single-image::before {
   content: "";
   display: block;
   padding-top: 60%;
}

.case-intro-image .single-image .overlay-image {
   height: 120%;
   top: -10%;
}

main.touch .case-intro-image .btn-wrap {
   transform: translate(-50%, -50%);
}

@media screen and (max-width: 1000px) {
   .case-intro-image .single-image::before {
      padding-top: 80%;
   } 
}

@media screen and (max-width: 720px) {

   .case-intro-image .container {
      padding: 0;
   }

   .case-intro-image .single-image::before {
      padding-top: 100%;
   } 

   main.touch .case-intro-image .btn-wrap,
   .case-intro-image .btn-wrap { 
      transform: translate(-5vw, -50%);
      transform: translate(-10vw, -130%);
   }

   .case-intro-image.block-padding-bottom {
      padding-bottom: 0;
   }
}

/* ------------------------- Case - Footer -------------------------------------------------- */


.footer-case-wrap .container:nth-child(1) .row:nth-child(1) p {
   text-align: center;
   margin-bottom: calc(var(--section-padding) / 4);
}

.footer-case-wrap .footer h2 {
   font-size: calc(clamp(3.25em, 7vw, 8em) * 1);
   text-align: center;
   transition: var(--animation-primary);
}

.footer-case-wrap .container.medium .row:nth-child(3) .flex-col {
   justify-content: center;
}

.footer-case-wrap .container.medium .row:nth-child(1) {
   padding-bottom: calc(var(--section-padding) / 1.25);
}

.footer-case-wrap .bottom-footer {
   padding-top: calc(var(--section-padding) / 1.9);
}

.footer-case-wrap .next-case-btn:hover {
   text-decoration: none;
}

.footer-case-wrap .next-case-btn:hover h2 {
   opacity: .25;
}

.footer-case-wrap .tile-image-wrap {
   width: calc(clamp(10em, 27.5vw, 25em) * .9);
   position: absolute;
   left: 50%;
   bottom: 0;
   background: transparent;
   transform: translateX(-50%);
   overflow: hidden;
   z-index: 2;
}

.footer-case-wrap .tile-image-wrap::before {
   display: block;
   content: "";
   padding-bottom: 50%;
}

.footer-case-wrap .tile-image-wrap .tile-image {
   width: 100%;
   position: absolute;
   top: 0;
   left: 0;
   transform: translateY(30%);
   transition: var(--animation-primary);
}

.footer-case-wrap .next-case-btn:hover .tile-image-wrap .tile-image {
   transform: translateY(0%);
}

.footer-case-wrap .tile-image-wrap .tile-image::before {
   content: "";
   display: block;
   padding-bottom: 85%;
}

@media screen and (max-width: 1000px){

   .footer-case-wrap .tile-image-wrap {
      width: 40vw;
   }

   .footer-case-wrap .tile-image-wrap .tile-image {
      transform: translateY(20%);
   }
}

@media screen and (max-width: 720px){

   .footer-case-wrap .container.medium .row:nth-child(2) {
      padding-bottom: calc(var(--section-padding) * 1);
   }

   .footer-case-wrap .container.medium .row:nth-child(1) {
      padding-bottom: calc(var(--section-padding) / .5); 
      padding-bottom: 13em;
   }

   .footer-case-wrap .tile-image-wrap {
      width: 50vw;
   }

   .footer-case-wrap .tile-image-wrap .tile-image {
      transform: translateY(0%);
   }

   .footer-case-wrap .next-case-btn:hover h2 {
      opacity: 1;
   }
}

@media screen and (max-width: 540px){ 
   .footer-case-wrap .tile-image-wrap {
      width: 70vw;
   }

   .footer-case-wrap .tile-image-wrap::before {
      padding-bottom: 60%;
   }
}

/* ------------------------- Case - Pagebuilder Blocks -------------------------------------------------- */

.single-block {
   padding-bottom: 0;
   margin-top: -1px;
   background: var(--color-white);
}

.single-block .dark-overlay {
   background: var(--color-dark-dark);
}

.single-block.block-padding-bottom {
   padding-bottom: var(--section-padding);
}

/* ------------------------- Case - Block: Fullwidth -------------------------------------------------- */

.block-fullwidth {
   padding-top: 0;
   padding-bottom: 0;
   background: rgba(100, 100, 100, 0.1);
}

.block-fullwidth .single-image {
   width: 100%;
   overflow: hidden;
   position: relative;
}

.block-fullwidth .single-image::before {
   content: "";
   display: block;
   padding-top: 66%;
}

.block-fullwidth .single-image .overlay-image {
   height: 120%;
   top: -10%;
}

.block-fullwidth .text-overlay {
   display: flex;
   align-items: center;
   justify-content: center;
}

.block-fullwidth .text-overlay h2 {
   margin: 0;
   text-align: center;
}

@media screen and (max-width: 540px){ 
   .block-fullwidth .single-image::before {
      padding-top: 100%;
   }
}

/* ------------------------- Case - Block: Devices -------------------------------------------------- */

.block-device .overlay-device-image {
   width: 100%;
   position: relative;
}

.block-device .overlay-device-image::before {
   content: "";
   display: block;
   padding-top: 56.8%;
}

.block-device .single-image {
   width: 100%;
   background: rgba(100, 100, 100, 0.1);
   overflow: hidden;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}

.block-device .single-image::before {
   content: "";
   display: block;
   padding-top: 62.5%;
}

.block-device .single-image .parallax-image-wrap {
   width: 100%;
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
}

.block-device .single-image .image-wrap img {
   width: 100%;
}

.block-device .overlay-top {
   position: absolute;
   object-fit: contain;
   position: absolute;
   top: 0;
   height: unset;
}

/* Device MBP 16 Lower */

.block-device .device-mbp16lower {
   padding-left: calc(var(--container-padding) / 2);
   padding-right: calc(var(--container-padding) / 2);
}

.block-device .device-mbp16lower .single-image {
   width: 79.5%;
   top: 45.3%;
}

@media screen and (max-width: 540px){ 
   .block-device .device-mbp16lower {
      padding-left: 0;
      padding-right: 0;
   }
}

/* Device MBP 15 */

.block-device .device-mbp15 .overlay-device-image::before {
   content: "";
   display: block;
   padding-top: 50.365%;
}

.block-device .device-mbp15 {
   padding-left: calc(var(--container-padding) / 2);
   padding-right: calc(var(--container-padding) / 2);
}

.block-device .device-mbp15 .single-image {
   width: 74.4%;
   left: 49.95%;
   top: 47.3%;
   border-radius: 5px 5px 0 0;
}

@media screen and (max-width: 540px){ 
   .block-device .device-mbp15 {
      padding-left: 0;
      padding-right: 0;
   }
}

/* Device MacPro */

.block-device .device-macpro .overlay-device-image::before {
   content: "";
   display: block;
   padding-top: 85.5%;
}

.block-device .device-macpro {
   padding-left: calc(var(--container-padding) / 1);
   padding-right: calc(var(--container-padding) / 1);
}

.block-device .device-macpro video.overlay,
.block-device .device-macpro img.overlay {
   object-position: center top;
}

.block-device .device-macpro .single-image {
   width: 98.7%;
   top: 33.4%;
}

.block-device .device-macpro .single-image::before {
   padding-top: 57%;
}

@media screen and (max-width: 540px){ 
   .block-device .device-macpro {
      padding-left: 0;
      padding-right: 0;
   }
}

/* Device MacPro Higher */

.block-device .device-macprohigher .overlay-device-image::before {
   content: "";
   display: block;
   padding-top: 85.5%;
}

.block-device .device-macprohigher {
   padding-left: calc(var(--container-padding) / 1);
   padding-right: calc(var(--container-padding) / 1);
}

.block-device .device-macprohigher .single-image {
   width: 98.8%;
   top: 37%;
}

@media screen and (max-width: 540px){ 
   .block-device .device-macprohigher {
      padding-left: 0;
      padding-right: 0;
   }
}


/* Device No Device */

.block-device .device-nodevice .single-image {
   position: relative;
   top: unset;
   left: unset;
   transform: translate(0, 0);
}

.block-device .device-nodevice {
   padding-left: calc(var(--container-padding) / 1);
   padding-right: calc(var(--container-padding) / 1);
}

@media screen and (max-width: 540px){ 
   .block-device .device-nodevice {
      padding-left: 0;
      padding-right: 0;
   }
}

/* Device iPad Pro */

.block-device .device-ipadpro .overlay-device-image::before {
   content: "";
   display: block;
   padding-top: 63.7%;
}

.block-device .device-ipadpro {
   padding-left: calc(var(--container-padding) / 1.25);
   padding-right: calc(var(--container-padding) / 1.25);
}

.block-device .device-ipadpro .single-image {
   width: 97%;
   top: 50%;
   border-radius: 2.5%/4%;
   left: 50.1%;
}

.block-device .device-ipadpro .overlay-pencil {
   height: 60%;
   width: auto;
   left: 71%;
   top: 51%;
}

@media screen and (max-width: 540px){ 
   .block-device .device-ipadpro {
      padding-left: 0;
      padding-right: 0;
   }
}



/* ------------------------- Case - Block: Mobile Double -------------------------------------------------- */

.block-mobile-devices {
   padding: 0;
   overflow: hidden;
}

.block-mobile-devices .container.block-padding-sides {
   padding: 0 var(--container-padding);
}

.block-mobile-devices .flex-col {
   width: 33.333%;
   padding-top: var(--section-padding);
   display: flex;
   justify-content: center;
}

.block-mobile-devices .flex-col.block-padding-bottom {
   padding-bottom: var(--section-padding);
}

.block-mobile-devices .device {
   width: 55%;
   position: relative;
   display: flex;
   justify-content: center;
}

.block-mobile-devices .overlay-device-image {
   width: 100%;
   position: relative;
}

.block-mobile-devices .overlay-device-image::before {
   content: "";
   display: block;
   padding-top: 202%;
}

.block-mobile-devices .single-image {
   width: 90.2%;
   background: rgba(100, 100, 100, 0.1);
   overflow: hidden;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   border-radius: 10%/5%;
}

.block-mobile-devices .single-image::before {
   content: "";
   display: block;
   padding-top: 216%;
}

.block-mobile-devices .device-nodevice .single-image,
.block-mobile-devices .device-nodevicerounded .single-image {
   width: 100%;
}

@media screen and (max-width: 540px){ 
   .block-mobile-devices .container.block-padding-sides {
      padding: 0;
   }
}

/* Device No Device */

.block-mobile-devices .device-nodevice .single-image {
   position: relative;
   top: unset;
   left: unset;
   transform: translate(0, 0);
   border-radius: 0;
   background-color: transparent;
}

/* Device No Device Rounded */

.block-mobile-devices .device-nodevicerounded .single-image {
   position: relative;
   top: unset;
   left: unset;
   transform: translate(0, 0);
   background-color: transparent;
   border-radius: 10%/5%;
}

/* Amount 3 */

.block-mobile-devices .container.block-padding-sides.amount-3 .device {
   width: 68%;
}

@media screen and (max-width: 540px) {
   .block-mobile-devices .container.block-padding-sides.amount-3 .device {
      width: 76%;
   }
}

/* Amount 2 */

.block-mobile-devices .amount-2 .flex-col {
   width: 50%;
}

.block-mobile-devices .amount-2 .device {
   width: 40%;
}

.block-mobile-devices .container.block-padding-sides.amount-2 {
   padding: 0 calc(var(--container-padding) * 1.5);
}
.block-mobile-devices .container.block-padding-sides.amount-2 .device {
   width: 55%;
}

@media screen and (max-width: 540px) {
   .block-mobile-devices .container.block-padding-sides.amount-2 {
      padding: 0 calc(var(--container-padding) * 1);
   }
   .block-mobile-devices .container.block-padding-sides.amount-2 .device {
      width: 80%;
   }
}

/* Amount 1 */

.block-mobile-devices .amount-1 .flex-col {
   width: 100%;
}

.block-mobile-devices .amount-1 .device {
   width: 30%;
}

@media screen and (max-width: 540px) {
   .block-mobile-devices .amount-1 .device {
      width: 60%;
   }
}


/* ------------------------- Case - Block: Screenshot -------------------------------------------------- */

.block-screenshot .row {
   padding: 0 var(--container-padding);
}

.block-screenshot .device {
   position: relative;
   display: flex;
   justify-content: center;
}

.block-screenshot .single-image {
   width: 100%;
   background: rgba(100, 100, 100, 0.1);
}

.block-screenshot .single-image img {
   width: 100%;
}

.block-screenshot .single-image video {
   width: 100%;
}

.block-screenshot .overlay-device-image {
   width: 100%;
   position: absolute;
   top: 0;
   left: 50%;
   transform: translate(-50%, 0);
}

.block-screenshot .overlay-device-image::before {
   content: "";
   display: block;
   padding-top: 56.8%;
}

@media screen and (max-width: 540px){ 
   .block-screenshot .row {
      padding: 0;
   }
}

/* Device MBP 16 Lower */

.block-screenshot .device-mbp16 .single-image {
   width: 79.2%;
}

.block-screenshot .device-mbp16 {
   padding-left: calc(var(--section-padding) / 2);
   padding-right: calc(var(--section-padding) / 2);
}

.block-screenshot .device-mbp16 .overlay-device-image {
   transform: translate(-50%, -1.7%);
}


/* ------------------------- Case - Block: Button -------------------------------------------------- */

.block-button .container .flex-col {
   z-index: 1;
}

.block-button .row {
   flex-wrap: nowrap;
   justify-content: center
}

.block-button .row .flex-col {
   width: unset;
   margin-right: calc(var(--gap-padding) / 2);
}

.block-button .row .flex-col:last-child {
   margin-right: 0;
}

.about-services .row:nth-child(2) {
  padding-top:0 !important;
}