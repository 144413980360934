#cursor {
    position: fixed;
    z-index: 99;
    left: -80px;
    top: -80px;
    pointer-events: none;
    will-change: transform;
    mix-blend-mode: difference;
    transform: translate();
  }
  @media (hover: hover) and (pointer: fine) {
  .cursor__circle {
    width: 64px;
    height: 64px;
    margin-top: 20px;
    margin-left: 20px;
    border-radius: 50%;
    border: solid 1px rgba(227, 222, 193, 0.64);
    transition: opacity 0.3s cubic-bezier(0.25, 1, 0.5, 1),
      background-color 0.3s cubic-bezier(0.25, 1, 0.5, 1),
      border-color 0.3s cubic-bezier(0.25, 1, 0.5, 1),
      width 0.3s cubic-bezier(0.25, 1, 0.5, 1),
      height 0.3s cubic-bezier(0.25, 1, 0.5, 1);
  }
}
  
  #cursor.arrow .cursor__circle {
    width: 128px;
    height: 128px;
    background-color: #073099;
    border-color: #073099;
  }
  
  #cursor.arrow::after {
    content: '';
    position: absolute;
    left: -50%;
    top: -50%;
    width: 100%;
    height: 100%;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yMS44ODMgMTJsLTcuNTI3IDYuMjM1LjY0NC43NjUgOS03LjUyMS05LTcuNDc5LS42NDUuNzY0IDcuNTI5IDYuMjM2aC0yMS44ODR2MWgyMS44ODN6Ii8+PC9zdmc+');
    background-repeat: no-repeat;
    background-position: center;
  }
  
  #cursor.subtle .cursor__circle {
    opacity: 0.16;
  }
  
  #cursor.overlay .cursor__circle {
    width: 48px;
    height: 48px;
    background-color: rgba(227, 222, 193, 0.08);
    border-color: transparent;
  }
  