.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    position: relative;
    border-radius: 10px;
    max-width: 700px;
    text-align: left;
    padding: 20px;
    border: 1px solid rgba(128, 128, 128, 0.299);
    background: linear-gradient(119deg, rgba(14, 3, 30, 0.918) -40%, rgb(0 11 53) 100%);
    transition: background-color .25s cubic-bezier(0.36, 0, 0.66, 0), box-shadow .25s cubic-bezier(0.36, 0, 0.66, 0);
    box-shadow: 0px 0px 20px 1px #000b3989;
  }
  @media screen and (max-width: 768px) {
    .modal-content {
        margin: 0px 16px;
    }   
  }
  
  .modal-content h1 {
    margin-bottom: 10px;
    padding: 10px 20px;
    color: white;
  }
  
  .modal-content p {
    margin-bottom: 20px;
    padding: 10px 20px;
  }
  
  .modal-content button {
    margin: 10px 20px;
    padding: 10px 20px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .modal-content button:hover {
    background: #0056b3;
  }
  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 27px;
    cursor: pointer;
    color: white;
    transition: color 0.3s ease;
  }
  
  .close-icon:hover {
    color: rgb(188, 188, 189);
  }