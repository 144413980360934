:root{
    --gap-padding: clamp(1.5em, 4vw, 2.5em);
}
/* when sceolled */

/* not scrolled yet */
.btn-hamburger {
    position: fixed !important;
    top: calc(var(--gap-padding) / 1.5);
    right: calc(var(--gap-padding) / 1.5);
    transition: transform .4s cubic-bezier(0.36, 0, 0.66, 0);
    transform: translateY(0%) scale(0) rotate(0.001deg);
    z-index: 105 !important;
}

main.scrolled .btn-hamburger {
    transition: transform .4s cubic-bezier(0.34, 1.5, 0.64, 1);
    transform: translateY(0%) scale(1) rotate(0.001deg);
}
.btn {
    margin-bottom: calc(var(--gap-padding) / 2);
    position: relative;
    z-index: 5;
    border: 0;
    outline: 0;
}

.nav-active .fixed-nav {
    transform: translate(0, 0) rotate(0.001deg);
}


.btn-hamburger .btn-click {
    border: 1px solid rgba(128, 128, 128, 0.299);
    background: linear-gradient(119deg, rgba(14, 3, 30, 0.918) -40%, rgb(0, 31, 153) 100%);
    transition: background-color .25s cubic-bezier(0.36, 0, 0.66, 0), box-shadow .25s cubic-bezier(0.36, 0, 0.66, 0);
    /* box-shadow: inset 0px 0px 0px 1px rgb(255, 255, 255); */
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    box-shadow: 0px 0px 20px 1px #000b3989;
}


h5 {
    font-family: 'Dennis Sans', sans-serif;
    font-weight: 450;
    font-style: normal;
    font-size: .7em !important;
    line-height: 1.065;
    margin-bottom: 1em;
    text-transform: uppercase;
    letter-spacing: .05em;
    opacity: .5;
}
.socials h5 {
    margin-top: 2.5rem;
    margin-bottom: 1em;
}

.fixed-nav-inner {
    padding: 12vh 7.5vw 10vh 7.5vw;
}