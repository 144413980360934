
html {
  scroll-behavior: smooth !important; 
}

/* Define CSS variables for a modern typography system */
:root {
  --font-family-sans: 'Inter', 'Helvetica Neue', Arial, sans-serif;
  --font-family-serif: 'Merriweather', serif;
  --font-family-mono: 'Courier New', Courier, monospace;
  
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  
  --font-size-xs: 0.75rem; /* 12px */
  --font-size-sm: 0.875rem; /* 14px */
  --font-size-base: 1rem; /* 16px */
  --font-size-lg: 1.125rem; /* 18px */
  --font-size-xl: 1.25rem; /* 20px */
  --font-size-2xl: 1.5rem; /* 24px */
  --font-size-3xl: 1.875rem; /* 30px */
  --font-size-4xl: 2.25rem; /* 36px */
  --font-size-5xl: 3rem; /* 48px */
  --font-size-6xl: 4rem; /* 64px */
  
  --line-height-tight: 1.2;
  --line-height-normal: 1.5;
  --line-height-relaxed: 1.75;

  --letter-spacing-tight: -0.015em;
  --letter-spacing-normal: 0;
  --letter-spacing-wide: 0.015em;
  
  --color-primary: #1E1E21;
  --color-secondary: #6B7280;
  --color-accent: #3B82F6;
  --color-background: #FFFFFF;
  --color-muted: #9CA3AF;
}

/* Global reset */
body, h1, h2, h3, h4, h5, h6, p, a, ul, ol, li {
  margin: 0;
  padding: 0;
  font-weight: inherit;
  line-height: inherit;
}

/* Global styles */
body {
  font-family: var(--font-family-sans);
  font-size: var(--font-size-base);
  line-height: var(--line-height-normal);
  color: var(--color-primary);
  /* background-color: var(--color-background); */
  background-color: unset !important;
}

html{
  /* background: linear-gradient(119deg, rgb(0 19 93) 0%, #0e031e 100%); */
  background-color: #0e031e;
}
#root{
  /* background-color: #3B82F6 ; */
  /* background-color: #0e031e; */
  min-height: 100vh;
  background-color: unset;
  /* background: linear-gradient(119deg, rgb(0 19 93) 0%, #0e031e 100%); */
}
* {
  scrollbar-width: none; /* For Firefox */
}
/* Headings */
.heading-1, h1 {
  font-size: var(--font-size-5xl);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-tight);
  letter-spacing: var(--letter-spacing-tight);
}

.heading-2, h2 {
  font-size: var(--font-size-4xl);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-tight);
  letter-spacing: var(--letter-spacing-tight);
}

.heading-3, h3 {
  font-size: var(--font-size-3xl);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-tight);
  letter-spacing: var(--letter-spacing-tight);
}

.heading-4, h4 {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-tight);
  letter-spacing: var(--letter-spacing-tight);
}

.heading-5, h5 {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-normal);
  letter-spacing: var(--letter-spacing-normal);
}

.heading-6, h6 {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-normal);
  letter-spacing: var(--letter-spacing-normal);
}

/* Paragraphs */
.paragraph, p {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-normal);
  letter-spacing: var(--letter-spacing-normal);
  margin-bottom: 1rem;
}

.paragraph-small, p.small {
  font-size: var(--font-size-sm);
  line-height: var(--line-height-relaxed);
}

.paragraph-large, p.large {
  font-size: var(--font-size-lg);
  line-height: var(--line-height-relaxed);
}

/* Links */
.link, a {
  color: var(--color-accent);
  text-decoration: none;
  transition: color 0.3s;
}

.link:hover, a:hover {
  text-decoration: underline;
}

/* Text Styles */
.text-muted {
  color: var(--color-muted);
}

.text-accent {
  color: var(--color-accent);
}

.text-bold {
  font-weight: var(--font-weight-bold);
}

.text-italic {
  font-style: italic;
}

.text-uppercase {
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-wide);
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-mono {
  font-family: var(--font-family-mono);
}

.text-serif {
  font-family: var(--font-family-serif);
}

/* Text Alignment */
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

/* Responsive Typography */
@media (max-width: 767px) {
  .heading-1, h1 {
    font-size: var(--font-size-4xl);
  }
  .heading-2, h2 {
    font-size: var(--font-size-3xl);
  }
  .heading-3, h3 {
    font-size: var(--font-size-2xl);
  }
  .heading-4, h4 {
    font-size: var(--font-size-xl);
  }
  .heading-5, h5 {
    font-size: var(--font-size-lg);
  }
  .heading-6, h6 {
    font-size: var(--font-size-base);
  }
  .paragraph, p {
    font-size: var(--font-size-sm);
  }
  .paragraph-large, p.large {
    font-size: var(--font-size-base);
  }
}


html {
  scroll-behavior: smooth !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --animate-delay: 0.5s;
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}



.about-section{
  transform: translateY(100px);
}
.scroll-container {
  will-change: transform;
}
