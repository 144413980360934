@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.scroll-down-container {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 1111111111;
  opacity: 0; 
}

.scroll-down-container.visible {
  animation: fadeIn 0.5s forwards;
}

.scroll-down-container.hide {
  animation: fadeOut 0.5s forwards;
}
@media (min-width: 810px) and (max-width: 1199px) {
    .framer-DD5FM .framer-2ziyqt-container {
        bottom: 80px;
        left: 82%;
        order: 2;
        right: unset;
        transform: translate(-50%);
        width: 196px;
    }
}
.framer-DD5FM .framer-2ziyqt-container {
    bottom: 132px;
    flex: none;
    height: auto;
    position: absolute;
    right: 32px;
    width: auto;
    z-index: 10;
}

.framer-1ofjumu {
    align-content: center;
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 10px;
    height: 196px;
    justify-content: center;
    overflow: hidden;
    padding: 0;
    position: relative;
    text-decoration: none;
    width: 196px;
}

 .framer-cprvjm-container {
    flex: none;
    height: 159px;
    left: calc(50.00000000000002% - 159px / 2);
    position: absolute;
    top: calc(50.00000000000002% - 159px / 2);
    width: 159px;
    z-index: 1;
}

/* arrow cont  */
 .framer-1475qda {
    aspect-ratio: 1 / 1;
    flex: none;
    height: 186px;
    left: 50%;
    overflow: hidden;
    position: absolute;
    top: 50%;
    width: 186px;
    z-index: 0;
}
/* arrow cont sub cont */
 .framer-pizd6a {
    aspect-ratio: 1 / 1;
    flex: none;
    height:  96px;
    left: 50%;
    overflow: hidden;
    position: absolute;
    top: 50%;
    width: 96px;

    /* will-change: var(--framer-will-change-override, transform); */
    z-index: 5;
}


 .framer-1gqhdmb {
    flex: none;
    height: 54px;
    left: calc(50.00000000000002% - 54px / 2);
    position: absolute;
    top: calc(50.00000000000002% - 54px / 2);
    width: 54px;
}

[data-border=true]:after,  [data-border=true]:after {
    content: "";
    border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0);
    border-color: var(--border-color, none);
    border-style: var(--border-style, none);
    width: 100%;
    height: 100%;
    position: absolute;
    box-sizing: border-box;
    left: 0;
    top: 0;
    border-radius: inherit;
    pointer-events: none;
}

.transform-origin-center-center{
animation: rotate 8s linear infinite;
}

@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

 

  .framer-v-1ofjumu:hover .framer-cprvjm-container {
    transform: scale(0); /* Scale down on hover */
  }

  .framer-pizd6a{
      transform: translate(-50%, -50%);
      transition: transform 0.5s;
  }

  .framer-v-1ofjumu:hover .framer-pizd6a{
    transform: translate(-50%, -50%) rotate(-45deg) translateZ(0);
  }
.framer-1475qda{
    opacity: 1;
    transition: opacity 0.5s;
}
  .framer-v-1ofjumu:hover .framer-1475qda{
    opacity: 0 ;
  }

/* ignore this comment for responsiveness it have nothing to do with anything */
  /* .framer-1e8d3zx {
    height: 203px;
    width: 100px !important;
    background-color: white !important;
} */


 .framer-1e8d3zx {
    transform: scale(0) !important;
}

.framer-v-1ofjumu:hover .framer-1e8d3zx {
    aspect-ratio: 1 / 1;
    overflow: hidden;
    position: absolute;
    width: 1px;
    z-index: 3;
    height: 96px;
    width: 96px !important;
    transition: transform 0.5s;
    transform: scale(1) !important;
}

.framer-v-1ofjumu:hover .framer-1gqhdmb {
    filter: grayscale(100%) brightness(0%);
}


/* Responsive styles ////////////////////////////////////////////////*/ 
@media (min-width: 810px) and (max-width: 1199px) {
  /* .framer-DD5FM .framer-2ziyqt-container {
      bottom: 80px;
      left: 82%;
      right: unset;
      transform: translate(-50%);
      width: 196px;
  } */

  .framer-1ofjumu {
      width: 140px;
      height: 140px;
  }

  .framer-cprvjm-container {
      width: 104px;
      height: 104px;
      left: calc(50.00000000000002% - 104px / 2);
      top: calc(50.00000000000002% - 104px / 2);
  }

  .framer-1475qda {
      width: 125px;
      height: 125px;
  }

  .framer-pizd6a {
      width: 63px;
      height: 63px;
  }

  .framer-1gqhdmb {
      width: 37px;
      height: 37px;
    left: calc(50.00000000000002% - 37px / 2);
    top: calc(50.00000000000002% - 37px / 2);
  }
  .framer-v-1ofjumu:hover .framer-1e8d3zx {
    transform: scale(0.9) !important;
  }
}

@media (max-width: 809px) {
  .framer-1ofjumu {
    width: 80px;
    height: 80px;
}

.framer-cprvjm-container {
    width: 60px;
    height: 60px;
    left: calc(50% - 30px);
    top: calc(50% - 30px);
}

.framer-1475qda {
    width: 75px;
    height: 75px;
}

.framer-pizd6a {
    width: 39px;
    height: 39px;
}

.framer-1gqhdmb {
    width: 20px;
    height: 20px;
    left: calc(50% - 10px);
    top: calc(50% - 10px);
}
.framer-v-1ofjumu:hover .framer-1e8d3zx {
  transform: scale(0.6) !important;
}

}