.music-wrap {
  z-index: 1111111111888888888;
  position: fixed;
  top: calc(clamp(1.5em, 4vw, 2.5em) / 1.5);
  left: calc(clamp(1.5em, 4vw, 2.5em) / 1.5);
  width: clamp(4em, 5.5vw, 5em);
  height: clamp(4em, 5.5vw, 5em);
  overflow: hidden;
}

.sound-icon {
  width: 100%;
  height: 100%;
  border: 2px solid #fff;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.sound-icon .sound-waves {
  position: absolute;
  width: 95%;
  height: 95%;
  border-radius: 50%;
  border: 5px solid transparent;
}

.sound-waves-sub {
  position: absolute;
  width: 80%;
  height: 80%;
  border-radius: 50%;
  border: 5px solid transparent;
}

.sound-icon .wave1 {
  border-top: 3px solid #fff;
  animation: rotate1 1.5s linear infinite;
}

.sound-icon .wave2 {
  border-right: 3px solid #fff;
  animation: rotate2 2s linear infinite;
}

.sound-icon .wave3 {
  border-bottom: 3px solid #fff;
  animation: rotate3 2.5s linear infinite;
}

.sound-icon.off .sound-waves {
  display: none;
}
.sound-icon.off .sound-waves-sub {
  display: none;
}
.sound-icon .wave1-sub,
.sound-icon .wave2-sub,
.sound-icon .wave3-sub,
.sound-icon .wave4-sub,
.sound-icon .wave5-sub {
  border-top: 3px solid #fff;
}

.sound-icon .wave1-sub {
  animation: rotate1 1.5s linear infinite;
}

.sound-icon .wave2-sub {
  animation: rotate2 2s linear infinite;
}

.sound-icon .wave3-sub {
  animation: rotate3 2.5s linear infinite;
}

.sound-icon .wave4-sub {
  animation: rotate4 3s linear infinite;
}

.sound-icon .wave5-sub {
  animation: rotate5 3.5s linear infinite;
}

@keyframes rotate1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes rotate3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate4 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes rotate5 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.sound-text {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid #fff;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  transition: transform 0.3s ease;
}

.sound-text.on {
  transform: scale(0);
}

.sound-text.off {
  transform: scale(1);
}

.sound-text:hover {
  transform: scale(1.2);
}

.toggle-button {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #61dafb;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  color: #282c34;
}